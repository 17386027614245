import React from "react";

import { firestore } from "../../firebase";

import { Button, IconButton } from "@material-ui/core";
import { Edit, Add, Person } from "@material-ui/icons";
import MaterialTable from "material-table";
import AddUserDialog from "../AddUserDialog";

export default function UsersTable(props) {
  const [users, setUsers] = React.useState([]);
  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false);
  const [userFields, setUserFields] = React.useState();

  const columns = [
    { title: "שם פרטי", field: "firstName" },
    { title: "שם משפחה", field: "lastName" },
    { title: "שם משתמש", field: "displayName" },
    { title: "דואר אלקטרוני", field: "mail" },
    { title: "טלפון", field: "phone", type: "numeric" },
    { title: "הרשאות", field: "role" },
    {
      title: "תמונה",
      field: "avatarUrl",
      render: (rowData) =>
        rowData.avatarUrl ? (
          <img
            alt={"avatar"}
            src={rowData.avatarUrl}
            style={{
              width: 50,
              height: 50,
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
        ) : (
          <Person />
        ),
    },

    {
      title: "פעולות",
      field: "avatarUrl",
      render: (rowData) => (
        <IconButton
          onClick={(_e) => {
            setUserFields(rowData);
            setAddUserDialogOpen(true);
          }}
        >
          <Edit />
        </IconButton>
      ),
    },
  ];

  React.useEffect(() => {
    var users = [];
    firestore
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
      })
      .then(() => {
        setUsers(users);
      });
  }, [addUserDialogOpen]);

  return (
    <>
      <AddUserDialog
        open={addUserDialogOpen}
        onClose={() => {
          setAddUserDialogOpen(false);
          setUserFields();
        }}
        content={userFields}
      />
      <MaterialTable
        title=""
        columns={columns}
        data={users}
        actions={[
          {
            icon: "add",
            tooltip: "הוספת משתמש",
            isFreeAction: true,
            onClick: (event) => setAddUserDialogOpen(true),
          },
        ]}
        localization={{
          toolbar: { searchPlaceholder: "חיפוש", searchTooltip: "חיפוש" },
          pagination: {
            labelDisplayedRows: "{from}-{to} מתוך {count}",
            labelRowsSelect: "שורות",
          },
        }}
        components={{
          Action: (props) => (
            <Button
              onClick={(event) => props.action.onClick(event, props.data)}
              color="primary"
              variant="contained"
              style={{ textTransform: "none", padding: 5, margin: 10 }}
              size="small"
            >
              <Add />
              הוספת משתמש
            </Button>
          ),
        }}
      />
    </>
  );
}
