import React from "react";
import PropTypes from "prop-types";

import { WbSunny, NightsStay } from "@material-ui/icons";

export default function DayProgress(props) {
  return (
    <>
      <div
        style={{
          zIndex: 1000,
          width: 50,
          height: "calc(100vh - 150px)",
          right: 10,
          top: 80,
          position: "fixed",
          backgroundColor: "lightGrey",
        }}
      >
        <WbSunny
          style={{ position: "absolute", top: 20, left: 13.5, zIndex: 1005 }}
        />
        <NightsStay
          style={{ position: "absolute", bottom: 20, left: 13.5, zIndex: 1005 }}
        />

        <div //Overlay
          style={{
            width: 50,
            // height: "calc(100vh - 150px)",
            height: `calc(${props.value > 100 ? 100 : props.value}vh - 150px)`,
            right: 10,
            top: 80,
            position: "fixed",
            backgroundColor: "lightGrey",
            animation: "expandHeight 1000ms ease-out",
            backgroundImage: "linear-gradient(to bottom, yellow, grey)",
          }}
        />
        <div //Bottom triangle
          style={{
            position: "absolute",
            bottom: -10,
            width: 0,
            height: 0,
            borderLeft: "25px solid transparent",
            borderRight: "25px solid transparent",
            borderTop: `10px solid ${
              props.value === 100 || props.value > 100 ? "grey" : "lightGrey"
            }`,
            zIndex: 1005,
          }}
        />
      </div>
    </>
  );
}

DayProgress.propTypes = {
  value: PropTypes.any.isRequired,
};
