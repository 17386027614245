import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Paper, Tabs, Tab, Typography, Box } from "@material-ui/core";
import UsersTable from "../UsersTable/UsersTable";
import HelpDialog from "../HelpDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function AdminPage(props) {
  const classes = useStyles();

  const [selectTab, setSelectedTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  React.useEffect(() => {}, []);

  return (
    <>
      <Paper className={classes.root}>
        <HelpDialog
          videoUrl={""}
          buttonStyle={{ position: "absolute", top: 65, left: 0, zIndex: 1000 }}
        />
        <Tabs
          value={selectTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          centered
        >
          <Tab label="משתמשים" />
          <Tab label="יומן" />
          <Tab label="-" />
        </Tabs>
        <TabPanel value={selectTab} index={0}>
          <UsersTable />
        </TabPanel>
        <TabPanel value={selectTab} index={1}>
          <Typography>יומן המערכת, רשימה ארוכה של כל הפעולות במערכת</Typography>
        </TabPanel>
      </Paper>
    </>
  );
}
