/**
 * @deprecated November 29, 2020 Replaced with MUI autocomplete
 * @description  Form to create or edit a class
 * @author     Idan Mantzor
 * @version  0.1
 */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

import { Check, Close } from "@material-ui/icons";

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Hidden,
  ListItemIcon,
} from "@material-ui/core";

import appearance from "../../services/appearance";

import { auth, firestore } from "../../firebase";
import TeamSelect from "../TeamSelect";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    padding: 10,
  },
  formControl: {
    width: "100%",
    padding: 10,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  loadingDiv: {
    minWidth: 500,
    minHeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    paddingLeft: 15,
  },
  deleteButton: {
    background: "red",
    marginRight: "auto",
  },
}));

export default function ClassDialog(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [teamNames, setTeamNames] = React.useState([]);
  const [classFields, setClassFields] = React.useState({
    nickname: "",
    color: "",
    team: [],
    cognitive: "",
    physical: "",
    behavioral: "",
  });
  const { centerId } = useParams();

  React.useEffect(() => {
    if (props.selectedClass && classFields) {
      setLoading(true);
      firestore
        .collection("classes")
        .doc(props.selectedClass)
        .get()
        .then((snapshot) => {
          Object.keys(classFields).forEach((field) => {
            if (snapshot.data()[field]) {
              classFields[field] = snapshot.data()[field];
            }
          });
          setClassFields(classFields);
        })
        .then(() => setLoading(false));
    }
    var names = [];
    firestore
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          names.push(
            `${doc.data().firstName} ${doc.data().lastName},${
              doc.data().avatarUrl
            }`
          );
        });
      })
      .then(() => {
        setTeamNames(names.sort());
      });
    //eslint-disable-next-line
  }, [props.selectedClass]);

  const handleColorChange = (_, target) => {
    setClassFields({ ...classFields, color: target.props.value });
  };

  const handleSave = () => {
    setLoading(true);
    const collection = firestore.collection("classes");

    if (props.selectedClass) {
      collection
        .doc(props.selectedClass)
        .update(classFields)
        .then(() => {
          props.onClose();
        });
    } else {
      collection
        .add({ ...classFields, createdBy: auth.currentUser.uid })
        .then((docRef) => {
          collection.doc(docRef.id).update({
            time: new Date().toString(),
            id: docRef.id,
            centerId: centerId,
          });
          setLoading(false);
          props.onClose();
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    }
  };

  const deleteClass = async () => {
    let yes = prompt("האם להסיר את הכיתה מהמרכז? השיבי ״כן״ בכדי להסיר");
    if (yes === "כן") {
      await firestore.collection("logs").add({
        action: "delete class from center",
        uid: auth.currentUser.uid,
        email: auth.currentUser.email,
        time: new Date().getTime(),
        classId: props.selectedClass || "missing class id",
        centerId: centerId || "missing center id",
      });
      await firestore.collection("classes").doc(props.selectedClass).delete();
      props.onClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onBackdropClick={props.onClose}>
        <DialogTitle>
          <Typography variant="h6" component="div">
            יצירת כיתה
          </Typography>
          <Tooltip title="Close">
            <IconButton className={classes.closeButton} onClick={props.onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div className={classes.loadingDiv}>
              <CircularProgress />
            </div>
          ) : (
            <form>
              <TextField
                id="nickname"
                label="כינוי"
                InputLabelProps={{ className: classes.inputLabel }}
                value={classFields.nickname}
                required
                className={classes.textField}
                onChange={(event) =>
                  setClassFields({
                    ...classFields,
                    nickname: event.target.value,
                  })
                }
              />
              <FormControl required className={classes.formControl}>
                <InputLabel
                  className={classes.inputLabel}
                  id="color-select-label"
                >
                  צבע
                </InputLabel>
                <Select
                  labelId="color-select-label"
                  id="color-select"
                  value={classFields.color}
                  onChange={handleColorChange}
                  label="color"
                >
                  <MenuItem value="">
                    <em>-</em>
                  </MenuItem>
                  {Object.values(appearance.colors).map((color) => {
                    return (
                      <MenuItem value={color.id} key={color.id}>
                        <Hidden xsDown>
                          <ListItemIcon>
                            <Check
                              style={{ background: `${color.import[500]}` }}
                            />
                          </ListItemIcon>
                        </Hidden>
                        {color.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel
                  id="cognitive-functioning-label"
                  className={classes.inputLabel}
                >
                  תפקוד קוגנטיבי
                </InputLabel>
                <Select
                  labelId="cognitive-functioning-label"
                  id="cognitive-functioning"
                  value={classFields.cognitive}
                  onChange={(event) =>
                    setClassFields({
                      ...classFields,
                      cognitive: event.target.value,
                    })
                  }
                >
                  <MenuItem value={""}>
                    <em>-</em>
                  </MenuItem>
                  <MenuItem value={"low"}>נמוך</MenuItem>
                  <MenuItem value={"medium"}>בינוני</MenuItem>
                  <MenuItem value={"high"}>גבוה</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel
                  id="physical-functioning-label"
                  className={classes.inputLabel}
                >
                  תפקוד גופני
                </InputLabel>
                <Select
                  labelId="physical-functioning-label"
                  id="physical-functioning"
                  value={classFields.physical}
                  onChange={(event) =>
                    setClassFields({
                      ...classFields,
                      physical: event.target.value,
                    })
                  }
                >
                  <MenuItem value={""}>
                    <em>-</em>
                  </MenuItem>
                  <MenuItem value={"aid"}>סיעודי</MenuItem>
                  <MenuItem value={"mobilityAcc"}>אביזרי ניידות</MenuItem>
                  <MenuItem value={"independent"}>עצמאיים</MenuItem>
                </Select>
              </FormControl>{" "}
              <FormControl className={classes.formControl}>
                <InputLabel
                  id="behavioral-functioning-label"
                  className={classes.inputLabel}
                >
                  תפקוד התנהגותי
                </InputLabel>
                <Select
                  labelId="behavioral-functioning-label"
                  id="behavioral-functioning"
                  value={classFields.behavioral}
                  onChange={(event) =>
                    setClassFields({
                      ...classFields,
                      behavioral: event.target.value,
                    })
                  }
                >
                  <MenuItem value={""}>
                    <em>-</em>
                  </MenuItem>
                  <MenuItem value={"challenging"}>התנהגות מאתגרת</MenuItem>
                  <MenuItem value={"restless"}>אי-שקט</MenuItem>
                  <MenuItem value={"non"}>ללא</MenuItem>
                </Select>
              </FormControl>
              <TeamSelect
                options={teamNames}
                value={classFields.team}
                onChange={(_event, value) =>
                  setClassFields({ ...classFields, team: value })
                }
              />
              {/* <ChipSelect
                names={teamNames}
                populate={classFields.team}
                onChange={(change) => {
                  setClassFields({ ...classFields, team: change });
                }}
              /> */}
            </form>
          )}
        </DialogContent>
        <DialogActions>
          {props.selectedClass && (
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={deleteClass}
            >
              הסרה
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={
              !classFields.color ||
              !classFields.nickname ||
              !classFields.team.length
            }
          >
            {props.selectedClass ? "עדכון כיתה" : "יצירת כיתה"}
          </Button>
          <Button color="secondary" onClick={props.onClose}>
            חזרה
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ClassDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedClass: PropTypes.string,
};
