import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { auth } from "../../firebase";

import authentication from "../../services/authentication";

import EmptyState from "../EmptyState";

// import { ReactComponent as CabinIllustration } from "../../illustrations/cabin.svg";
// import { ReactComponent as InsertBlockIllustration } from "../../illustrations/insert-block.svg";

import CentersPage from "./CentersPage";
import Footer from "../Footer";

class HomePage extends Component {
  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(`${displayName || emailAddress}מחובר כ`);
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };

  render() {
    const { user } = this.props;

    if (user) {
      return <CentersPage />;
    }

    return (
      <EmptyState
        image={
          <img
            alt="Background"
            src={
              "https://firebasestorage.googleapis.com/v0/b/sederyommui.appspot.com/o/images%2FsederYom.png?alt=media&token=16509b02-c199-467a-adbe-93d810d29eb2"
            }
          />
        }
        footer={<Footer />}
        title="סדר יום"
        description="הצגת סדר יום קבוצתי בצורה מונגשת פשוטה ונוחה"
        team={[
          {
            img: "https://firebasestorage.googleapis.com/v0/b/sederyommui.appspot.com/o/images%2Favatars%2Fidan.png?alt=media&token=9ab488a6-9844-4260-839f-37d18890cf59",
            name: "עידן מנצור",
            job: "ארכיטקט תוכנה",
            href: "https://linkedin.com/in/idanmantzor",
          },
          {
            img: "https://firebasestorage.googleapis.com/v0/b/sederyommui.appspot.com/o/images%2Favatars%2Foranit.png?alt=media&token=c3aa8674-ea40-4757-b315-56dac1694a03",
            // img: "https://lh3.googleusercontent.com/a-/AOh14Gi9S5532AkOcq99AkwAzsBriJwheD9iK4yaHPaIgQ=s140-p-k-no",
            name: "אורנית אבידן זיו",
            job: "מנהלת פרוייקט",
            href: "",
          },
        ]}
        features={[
          {
            icon: "fa fa-comments-o",
            title: "",
            text: "היא תוכנת סדר יום שפותחה על ידי המכון הישראלי להנגשה קוגניטיבית ואגודת עמי. מדובר בתוכנה ייחודית בעברית, המציגה סדר יום של קבוצות בשפה פשוטה ובגרפיקה ברורה. המערכת מאפשרת את הצגת סדר היום הקבוצתי בנוחות ובפשטות עבור אנשים אשר מתקשים להבין סדר יום הבנוי כיומן.",
          },
          {
            icon: "fa fa-bullhorn",
            title: "קהל היעד",
            text: "המערכת מתאימה לשימוש במרכזי לימוד, מועדוני יום והוסטלים. המערכת היא בשפה פשוטה ובארגון נגיש, ועל כן מתאימה לאוכלוסיות יעד של אנשים עם מוגבלות קוגניטיבית, ילדים, קשישים, דוברי עברית כשפה שנייה וכל מי שמעוניין בסדר יום קבוצתי נגיש ופשוט. המערכת מאפשרת מעקב קל ופשוט אחר רצף הפעילויות, ללא תלות בהבנה של שעון. כמו כן, בשימוש נכון במערכת, ניתן להתאימה לאנשים אשר מתקשים בקריאה.",
          },
          {
            icon: "fa fa-group",
            title: "מאפיינים לשוניים",
            text: 'במערכת "הכל בסדר" יש הקפדה רבה על שפה פשוטה, מילים מוכרות ומבני משפט פשוטים. המערכת נבנתה בייעוץ של נשות מקצוע מתחום הבלשנות, הפסיכולוגיה והריפוי בעיסוק, אשר מתמחות בנגישות קוגניטיבית לשונית. כל מסגרת יכולה לקבוע את שמות הקבוצות והפעילויות בהתאם לנהוג במסגרת. כך, כל מסגרת יכולה לשמר בסדר היום את המושגים הלשוניים הייחודיים לה. לדוגמה: שמות קבוצות וחדרים כפי שנהוגים במקום, אנשי הצוות, חוגים ושיעורים.',
          },
          {
            icon: "fa fa-magic",
            title: "מאפיינים גרפיים",
            text: 'התצוגה במערכת נגישה, ברורה ונעימה, וכוללת תמונות או סמלים בהתאמה אישית. לדוגמה: סמל לחוג ספורט, סמל לארוחת צהריים. כך, המערכת מאפשרת גם לאנשים אשר מתקשים בקריאה להיות חלק מהתוכנית של סדר היום, ולקבל אוריינטציה לגבי סדר היום במסגרת. במערכת "הכל בסדר", אפשר לבחור את התצוגה שנוחה לכם: תצוגה של הפעילות שמתקיימת ממש עכשיו, תצוגה יומית או תצוגה שבועית של סדר היום של כל קבוצה. עיצוב המערכת עצמה גם הוא נבנה תחת יעוץ נשות מקצוע מתחום הנגישות הקוגניטיבית, וזאת על מנת ליצור יכולת אוריינטציה מירבית בתכנים המוצגים במערכת סדר היום.',
          },
          {
            icon: "fa fa-light",
            title: "התאמה אישית של המערכת",
            text: "במערכת קיימת אפשרות להציג ימי הולדת של חניכים, וכן מידע המספק אוריינטציה עבור החניכים והצוות. לדוגמה: יום, שעה ומזג האויר. בנוסף, המערכת מאפשרת להציג תזכורות חוזרות וחד-פעמיות, לפי בחירה. כך, גם הצוותים נהנים מסדר יום מאורגן וקל יותר. המערכת מאפשרת העלאה של מאגר תמונות ייחודי למסגרת. כך, מתאפשרת התאמה ייחודית של המערכת למאפיינים של המסגרת. המערכת מאפשרת שינויים המתעדכנים מיידית. כלומר, במידה וצוות המסגרת מעדכן שינוי בסדר היום, סדר היום העדכני יופיע במסכי סדר היום שברחבי המסגרת. לאחר הזנה של כל הנתונים העדכניים, המערכת יכולה לעבוד גם במצב שבו אין אינטרנט זמין.",
          },
        ]}
      />
    );
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

HomePage.propTypes = {
  user: PropTypes.object,
};

export default withRouter(HomePage);
