import React from "react";
import { useParams, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  IconButton,
  CardHeader,
} from "@material-ui/core";

import { Close } from "@material-ui/icons";

import moment from "moment";
import { firestore } from "../../firebase";
import { OpenWeatherMap } from "../WeatherCard";
import DayProgress from "./DayProgress";

import Skeleton from "@material-ui/lab/Skeleton";

import amiLogo from "../Assets/amiLogo.png";
import cognitiveLogo from "../Assets/cognitiveLogo.png";
import CenterCard from "./CenterCard";
import SpecialDayCard from "./SpecialDayCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "whiteSmoke",
  },
  topCard: {
    position: "absolute",
    borderRadius: 0,
    top: 0,
    left: 0,
    width: "100%",
    height: 65,
    paddingLeft: 50,
    zIndex: 1001,
    textAlign: "start",
    boxShadow: "0px 0px 25px 1px rgba(50, 50, 50, 0.1)",
  },
  topCardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  clock: {
    // border: "3px solid gray",
    fontFamily: "Monospace",
    borderRadius: 8,
    padding: 5,
    width: "fit-content",
  },
  pastActivityCard: {
    // position: "relative",
    borderRadius: 25,
    margin: 10,
    opacity: 0.5,
    border: "5px solid grey",
    boxShadow: "0px 0px 25px 1px rgba(50, 50, 50, 0.1)",
    height: "calc(25vh - 48px)",
  },
  futureActivityCard: {
    // position: "relative",
    borderRadius: 25,
    margin: 10,
    border: "5px solid grey",
    opacity: 0.5,
    height: "calc(25vh - 48px)",
  },
  sideCard: {
    // position: "relative",
    borderRadius: 25,
    margin: 10,
    padding: 10,
    // border: "5px solid lightGreen",
    display: "flex",
    alignContent: "center",
    height: "calc(25vh - 48px)",
    flexDirection: "column",
  },
  sideCardHeader: {
    alignSelf: "flex-start",
  },
  iconDiv: {
    position: "relative",
    // margin: theme.spacing(4),
    width: "fit-content",
    marginLeft: "auto",
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },

  weather: {
    // position: "absolute",
    marginRight: 20,
  },
  img: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  icon: {
    marginLeft: 10,
    marginRight: 10,
  },

  avatarChip: {
    height: 50,
    width: 50,
  },

  showTopButton: {
    position: "absolute",
    zIndex: 1002,
    left: 10,
    top: 10,
  },
  eventItem: {
    paddingTop: 20,
  },
  dayProgress: {
    position: "absolute",
    top: "calc(50vw - 50px)",
    left: "calc(-50vw + 25px)",
    width: "100%",
    height: 50,
    transform: "rotate(90deg)",
    backgroundColor: "white",
    zIndex: 1000,
    display: "flex",
  },
  generalCommentAvatar: {
    width: 30,
    height: 30,
    marginRight: 0,
  },
}));

const hoursToSubtract = 0;

export default function Present(_props) {
  const classes = useStyles();
  const history = useHistory();

  const { classId } = useParams();
  const [currentEvent, setCurrentEvent] = React.useState();
  const [parallelEvent, setParallelEvent] = React.useState();
  const [pastEvent, setPastEvent] = React.useState();
  const [futureEvent, setFutureEvent] = React.useState();
  const [classProgress, setClassProgress] = React.useState(0);
  const [todaysEvents, setTodaysEvents] = React.useState([]);
  const [eventRefresh, setEventRefresh] = React.useState(0);
  const [repeatingEvents, setRepeatingEvents] = React.useState([]);
  const [todaysProgress, setTodaysProgress] = React.useState(0);
  const [allDayEvents, setAllDayEvents] = React.useState([]);
  const [generalComments, setGeneralComments] = React.useState([]);
  const [classTitle, setClassTitle] = React.useState("");
  const [now, setNow] = React.useState(
    moment().subtract(hoursToSubtract, "hours").format()
  );

  React.useEffect(() => {
    fetchTodaysEvents();
    fetchRepeatingEvents();
    firestore
      .collection("classes")
      .doc(classId)
      .get()
      .then((snapshot) => {
        setClassTitle(snapshot?.data()?.nickname || "");
      });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    progress();
    // eslint-disable-next-line
  }, [now]);

  React.useEffect(() => {
    const timer = setInterval(
      () => setNow(moment().subtract(hoursToSubtract, "hours").format()),
      3000
    ); // clearing interval
    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    if (!todaysEvents.length && !repeatingEvents.length) return;

    var today = [...todaysEvents, ...repeatingEvents];
    today = today.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
    today.sort((a, b) =>
      a.start.split("T")[1].localeCompare(b.start.split("T")[1])
    );
    const generalComments = today.filter(
      (event) => event.generalComment === true
    );

    const allDay = today.filter(
      (event) => event.allDayEvent === true || event.birthday === true
    );

    today = today.filter((event) => event.allDayEvent !== true);

    if (today.length || allDay.length || generalComments.length) {
      setTodaysEvents(today);
      setAllDayEvents(allDay);
      setGeneralComments(generalComments);
    }
    // eslint-disable-next-line
  }, [eventRefresh]);

  React.useEffect(
    () => progress(),
    // eslint-disable-next-line
    [currentEvent]
  );

  const fetchTodaysEvents = () => {
    if (!now) return;
    firestore
      .collection("events")
      .where("classId", "==", classId)
      .where("date", "==", now.split("T")[0])
      .get()
      .then((querySnapshot) => {
        var events = [];
        querySnapshot.forEach((snapshot) => {
          events.push(snapshot.data());
        });
        events && setTodaysEvents(events);
      });
  };

  const fetchRepeatingEvents = () => {
    if (!now) return;
    var today = moment(now, moment.ISO_8601).day();
    firestore
      .collection("events")
      .where("classId", "==", classId)
      .where("endRecur", ">=", now)
      .get()
      .then((querySnapshot) => {
        var rEvents = [];
        querySnapshot.forEach((snapshot) => {
          if (
            snapshot.data().repetition !== "" &&
            snapshot.data().startRecur < now &&
            snapshot.data().daysOfWeek &&
            snapshot.data().daysOfWeek.includes(today)
          ) {
            rEvents.push(snapshot.data());
          }
        });
        setRepeatingEvents(rEvents);
        setEventRefresh(eventRefresh + 1);
      });
  };

  const progress = () => {
    // trigger next day
    var classProg = 0.01;
    if (currentEvent) {
      const n = moment(now.split("T")[1].substring(0, 8), "hh:mm:ss");
      const s = moment(
        currentEvent.start.split("T")[1].substring(0, 8),
        "hh:mm:ss"
      );
      const e = moment(
        currentEvent.end.split("T")[1].substring(0, 8),
        "hh:mm:ss"
      );
      const prog = s.diff(n, "seconds");
      const dur = s.diff(e, "seconds");

      classProg = (prog / dur) * 100 > 100 ? 100 : (prog / dur) * 100;
      setClassProgress(classProg > 100 ? 100 : classProg);
    }
    if (todaysEvents.length) {
      const nowTime = now.split("T")[1].substring(0, 5);
      const currentEventIndex = todaysEvents.findIndex(
        (event) => event.startTime < nowTime && event.endTime > nowTime
      );
      const futureEventIndex = todaysEvents.findIndex(
        (event) => event.startTime > nowTime && event.endTime > nowTime
      );

      if (
        todaysEvents[currentEventIndex + 1] &&
        todaysEvents[currentEventIndex] &&
        todaysEvents[currentEventIndex].startTime ===
          todaysEvents[currentEventIndex + 1].startTime &&
        todaysEvents[currentEventIndex].endTime ===
          todaysEvents[currentEventIndex + 1].endTime
      ) {
        setParallelEvent(todaysEvents[currentEventIndex + 1]);
      } else {
        setParallelEvent();
      }

      setCurrentEvent(todaysEvents[currentEventIndex]);
      setFutureEvent(todaysEvents[futureEventIndex]);
      setPastEvent(
        todaysEvents
          .reverse()
          .find((event) => event.startTime < nowTime && event.endTime < nowTime)
      );
      todaysEvents.reverse();

      var portion = moment(now.split("T")[1].substring(0, 8), "hh:mm:ss").diff(
        moment("07:30:00", "hh:mm:ss")
      );
      var whole = moment("17:00:00", "hh:mm:ss").diff(
        moment("07:30:00", "hh:mm:ss")
      );
      setTodaysProgress((portion / whole) * 100);
    }

    setNow(moment().subtract(hoursToSubtract, "hours").format());
  };

  return (
    <Paper className={classes.root}>
      <Grid container direction="column" justify="center" alignItems="stretch">
        <DayProgress value={todaysProgress} />

        <Card className={classes.topCard}>
          <CardContent className={classes.topCardContent}>
            <Typography variant={"h5"} className={classes.clock}>
              {now &&
                moment(now.split("T")[1].substring(0, 5), "hh:mm").format(
                  "h:mm A"
                )}
            </Typography>
            {classTitle && (
              <div>
                <Typography variant={"h5"}>
                  <b>{`מה קורה היום בקבוצה ה${classTitle}?`}</b>
                </Typography>
              </div>
            )}
            <div>
              <img
                alt={"amiLogo"}
                src={amiLogo}
                style={{ maxWidth: "25vw", maxHeight: 50, marginLeft: 30 }}
              />

              <img
                alt={"cognitiveLogo"}
                src={cognitiveLogo}
                style={{ maxWidth: "25vw", maxHeight: 55, marginLeft: 30 }}
              />
            </div>
          </CardContent>
        </Card>
        <IconButton
          className={classes.showTopButton}
          onClick={() => history.goBack()}
        >
          <Close />
        </IconButton>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Grid container alignItems="stretch" style={{ paddingRight: 55 }}>
              <Grid item xs={12} sm={9}>
                <Card className={classes.pastActivityCard}>
                  <CardContent>
                    <Grid container alignItems="stretch">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        className={classes.eventItem}
                      >
                        <Typography variant="h4">
                          {pastEvent ? pastEvent.title : <Skeleton />}
                        </Typography>
                        <Typography variant="h5">
                          {pastEvent ? pastEvent.description : <Skeleton />}
                        </Typography>
                        <Typography
                          variant={"h6"}
                          className={classes.eventDuration}
                        >
                          {pastEvent &&
                            `${moment(pastEvent.start, moment.ISO_8601).format(
                              "HH:mm"
                            )} - ${moment(
                              pastEvent.end,
                              moment.ISO_8601
                            ).format("HH:mm")}`}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.iconDiv}>
                          {pastEvent ? (
                            <Avatar className={classes.avatar}>
                              <img
                                alt={`past event ${pastEvent.icon}`}
                                src={pastEvent.icon}
                                className={classes.img}
                              />
                            </Avatar>
                          ) : (
                            <Skeleton
                              variant="circle"
                              height={120}
                              width={120}
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card className={classes.sideCard}>
                  <CardHeader
                    title={"מזג אוויר היום"}
                    className={classes.sideCardHeader}
                  />
                  <CardContent
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <OpenWeatherMap
                      city="Beer-Sheva"
                      country="IL"
                      time={now.split("T")[1].substring(0, 8)}
                      appid="837b527d82495411f2ac37321176f0a3"
                      className={classes.weather}
                    />
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={parallelEvent ? 6 : 9}>
                <CenterCard
                  currentEvent={currentEvent}
                  classProgress={classProgress}
                />
              </Grid>
              {parallelEvent ? (
                <Grid item xs={12} sm={6}>
                  <CenterCard
                    currentEvent={parallelEvent}
                    classProgress={classProgress}
                  />
                </Grid>
              ) : (
                <Grid item sm={3}>
                  <SpecialDayCard events={allDayEvents} />
                </Grid>
              )}
              <Grid item xs={12} sm={9}>
                <Card
                  className={classes.futureActivityCard}
                  style={{
                    border: `5px solid ${
                      futureEvent ? futureEvent.color : "grey"
                    }`,
                  }}
                >
                  <CardContent>
                    <Grid container alignItems="stretch">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        className={classes.eventItem}
                      >
                        <Typography variant="h4">
                          {futureEvent ? futureEvent.title : <Skeleton />}
                        </Typography>
                        <Typography variant="h5">
                          {futureEvent ? futureEvent.description : <Skeleton />}
                        </Typography>
                        <Typography
                          variant={"h6"}
                          className={classes.eventDuration}
                        >
                          {futureEvent ? (
                            `${moment(
                              futureEvent.start,
                              moment.ISO_8601
                            ).format("HH:mm")} - ${moment(
                              futureEvent.end,
                              moment.ISO_8601
                            ).format("HH:mm")}`
                          ) : (
                            <Skeleton />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.iconDiv}>
                          {futureEvent ? (
                            <Avatar className={classes.avatar}>
                              <img
                                alt={`future event ${futureEvent.icon}`}
                                src={futureEvent.icon}
                                className={classes.img}
                              />
                            </Avatar>
                          ) : (
                            <Skeleton
                              variant={"circle"}
                              height={120}
                              width={120}
                            />
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={3}>
                <Card className={classes.sideCard}>
                  <CardHeader
                    title={"חשוב לזכור"}
                    className={classes.sideCardHeader}
                  />
                  {generalComments &&
                    generalComments.map((event, index) => (
                      <div key={index} style={{ display: "flex" }}>
                        <Avatar
                          className={classes.generalCommentAvatar}
                          alt={event.icon}
                          src={event.icon}
                        />
                        <Typography variant="h6">{event.title}</Typography>
                      </div>
                    ))}
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}
Present.propTypes = {};
