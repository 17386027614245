import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

import CenterDialog from "../CenterDialog/CenterDialog";
import Footer from "../Footer";
import HelpDialog from "../HelpDialog";
import { firestore, auth } from "../../firebase";
import strings from "../../data/strings";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    textDecoration: "none",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
}));

export default function CentersPage() {
  const classes = useStyles();
  const [centerDialogOpen, setCenterDialogOpen] = React.useState(false);
  const [centers, setCenters] = React.useState([]);
  const [selectedCenter, setSelectedCenter] = React.useState();

  React.useEffect(() => {
    var fetched = [];
    firestore
      .collection("centers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.id === "3yU3BANkQvJoxRZzpLYQ") {
            if (auth.currentUser.email === "idan@admin.com") {
              fetched.push(doc.data());
            }
          } else {
            fetched.push(doc.data());
          }
        });
      })
      .then(() => setCenters(fetched));
  }, [centerDialogOpen]);

  return (
    <React.Fragment>
      <CssBaseline />
      <CenterDialog
        open={centerDialogOpen}
        onClose={() => setCenterDialogOpen(false)}
        center={selectedCenter}
      />
      <main>
        <div className={classes.heroContent}>
          <HelpDialog
            videoUrl={""}
            buttonStyle={{ position: "absolute", top: 65, left: 0 }}
            title={"הסבר על המערכת"}
          />
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              סדר יום
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              {strings().welcomeTitle}
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              בחרי מרכז בכדי להתחיל
            </Typography>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {centers.map((center) => (
              <Grid item key={center.id} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={
                      center.image ||
                      `https://source.unsplash.com/random?water,sig=${
                        Math.random() * 1000
                      }`
                    }
                    title="center"
                  />
                  <CardContent
                    className={classes.cardContent}
                    component={Link}
                    to={`/center/${center.id}`}
                  >
                    <Typography gutterBottom variant="h5" component="h2">
                      {center.name}
                    </Typography>
                    <Typography>{center.address}</Typography>
                    <Typography>{center.phone}</Typography>
                    <Typography>{center.email}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      component={Link}
                      to={`/center/${center.id}`}
                    >
                      למרכז
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        setSelectedCenter(center);
                        setCenterDialogOpen(true);
                      }}
                    >
                      הגדרות
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                className={classes.card}
                onClick={() => setCenterDialogOpen(true)}
              >
                <CardMedia
                  className={classes.cardMedia}
                  image="https://unsplash.com/photos/shallow-focus-photography-of-books-lUaaKCUANVI"
                  title="Add center image by @kimberlyfarmer"
                />
                <CardContent className={classes.cardContent}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    align="center"
                  >
                    הוספת מרכז
                  </Typography>
                  <Typography variant="h1" align="center" color="primary">
                    +
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
      <Footer />
    </React.Fragment>
  );
}
