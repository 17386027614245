import React from "react";

import { Typography, Link } from "@material-ui/core";
import amiLogo from "../Assets/amiLogo.png";
import cognitiveLogo from "../Assets/cognitiveLogo.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={`${classes.footer} hidden-print`}>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        component="p"
      ></Typography>

      <img
        alt={"amiLogo"}
        src={amiLogo}
        style={{ maxWidth: "25vw", maxHeight: 55, marginLeft: 20 }}
      />

      <img
        alt={"cognitiveLogo"}
        src={cognitiveLogo}
        style={{ maxWidth: "25vw", maxHeight: 55 }}
      />
      <Typography variant="body2" color="textSecondary" align="center">
        <Link color="inherit" href="https://mantzor.com/">
          mantzor.com
        </Link>
        {" © "}
        {new Date().getFullYear()}
      </Typography>
    </footer>
  );
}
