/**
 * @file TeamSelect.js
 * @description   Select dialog text field for team member with avatars
 * @author     Idan Mantzor
 * @version  0.1
 */

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Chip, Avatar, ListItem, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  listAvatar: {
    width: 35,
    height: 35,
    marginRight: 10,
  },
}));

export default function TeamSelect(props) {
  const classes = useStyles();

  return (
    <Autocomplete
      id="teamSelect"
      className={classes.root}
      options={props.options}
      multiple
      freeSolo
      value={props.value}
      onChange={props.onChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const opt = option.split(",");
          return (
            <Chip
              avatar={<Avatar alt={opt[0]} src={opt[1]} />}
              variant="outlined"
              label={opt[0]}
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderOption={(option, state) => {
        var opt = option.split(",");
        return (
          <ListItem selected={state.selected} component={"span"}>
            <Avatar className={classes.listAvatar} alt={opt[0]} src={opt[1]} />
            {opt[0]}
          </ListItem>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="צוות" placeholder="" />
      )}
    />
  );
}

TeamSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};
