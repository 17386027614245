import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Chip, Avatar, CardHeader } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  sideCardCenter: {
    borderRadius: 25,
    margin: 10,
    marginTop: 15,
    marginBottom: 15,
    padding: 10,
    // border: "5px solid gold",
    display: "flex",
    height: "calc((50vh - 48px)/2 - 15px)",
  },
  sideCardHeader: {
    alignSelf: "flex-start",
    alignItems: "flex-start",
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
  },
  chip: {
    margin: 12,
    padding: 12,
    [theme.breakpoints.up("sm")]: {
      height: 50,
      fontSize: 20,
      borderRadius: 30,
    },
    root: {
      avatar: {
        height: 70,
        width: 70,
      },
    },
  },
}));

export default function SpecialDayCard(props) {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.sideCardCenter}>
        <CardHeader
          title={"למי יש יום הולדת?"}
          className={classes.sideCardHeader}
        />
        <CardContent className={classes.cardContent}>
          {props.events &&
            props.events
              .filter((event) => event.birthday === true)
              .map((event, index) => (
                <Chip
                  key={index}
                  className={classes.chip}
                  avatar={
                    <Avatar
                      style={{
                        width: 100,
                        height: 100,
                        marginRight: 0,
                      }}
                      alt={event.birthdayAvatar}
                      src={
                        event.birthdayAvatar ||
                        "https://firebasestorage.googleapis.com/v0/b/sederyommui.appspot.com/o/iconsCohen%2F1051)%D7%99%D7%95%D7%9D%20%D7%94%D7%95%D7%9C%D7%93%D7%AA.png?alt=media&token=7b8492c3-c7c9-4f53-b754-2af739e384a6"
                      }
                    />
                  }
                  label={event.title}
                />
              ))}
        </CardContent>
      </Card>
      <Card className={classes.sideCardCenter}>
        <CardHeader
          title={"חגים וימים מיוחדים"}
          className={classes.sideCardHeader}
        />

        <CardContent className={classes.cardContent}>
          {props.events &&
            props.events
              .filter((event) => event.birthday === false)
              .map((event, index) => (
                <Chip
                  key={index}
                  className={classes.chip}
                  avatar={
                    <Avatar
                      style={{
                        width: 100,
                        height: 100,
                        marginRight: 0,
                      }}
                      alt={event.icon}
                      src={event.icon}
                    />
                  }
                  label={event.title}
                />
              ))}
        </CardContent>
      </Card>
    </>
  );
}
SpecialDayCard.propsTypes = {
  events: PropTypes.array,
};
