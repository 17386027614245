import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Close, Person } from "@material-ui/icons";

import * as firebase from "firebase/app";

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  Box,
  Badge,
  Fab,
  Fade,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import { firestore, storage } from "../../firebase";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    padding: 10,
    minWidth: 300,
  },
  formControl: {
    width: "100%",
    padding: 10,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  loadingDiv: {
    minWidth: 500,
    minHeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    paddingLeft: 15,
  },
  imageBox: {
    // justifyContent: "space-between",
    display: "flex",
    padding: 10,
  },
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(14),
  },

  loadingBadge: {
    top: "50%",
    right: "50%",
  },

  avatar: {
    marginRight: "auto",
    marginLeft: "auto",

    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  nameInitials: {
    cursor: "default",
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: "initial",
  },
  deleteButton: {
    background: "red",
    color: "white",
    marginRight: "auto",
  },
}));

export default function AddUserDialog(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [avatar, setAvatar] = React.useState();
  const [loadingAvatar, setLoadingAvatar] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [centers, setCenters] = React.useState([]);
  const [password, setPassword] = React.useState("");
  const [usingUserName, setUsingUsername] = React.useState(false);
  const [userFields, setUserFields] = React.useState({
    firstName: "",
    lastName: "",
    displayName: "",
    phone: "",
    mail: "",
    role: "-",
    avatarUrl: "",
    center: "-",
  });

  const performingAction = false;

  React.useEffect(() => {
    const centers = [];
    firestore
      .collection("centers")
      .get()
      .then((snapshot) => {
        snapshot.forEach((center) => {
          centers.push({ value: center.data().id, name: center.data().name });
        });
      })
      .then(() => setCenters(centers));
  }, []);

  React.useEffect(() => {
    if (props.content) {
      setLoading(true);
      setUserFields(props.content);
      setAvatar(props.content.avatarUrl);
      setLoading(false);
    } else {
      setUserFields({
        firstName: "",
        lastName: "",
        displayName: "",
        phone: "",
        mail: "",
        role: "-",
        avatarUrl: "",
        center: "-",
      });
    }
  }, [props.content]);

  const handleSave = () => {
    setLoading(true);
    const collection = firestore.collection("users");

    if (props.content) {
      collection
        .doc(props.content.id)
        .update({
          ...userFields,
          mail: usingUserName ? `${userFields.mail}@sdy.com` : userFields.mail,
          password: usingUserName ? password : "-",
        })
        .then(() => {
          setUserFields({
            firstName: "",
            lastName: "",
            displayName: "",
            phone: "",
            mail: "",
            role: "-",
            avatarUrl: "",
          });
          props.onClose();
        });
    } else {
      var secondaryApp;
      if (firebase.apps.length === 1) {
        secondaryApp = firebase.initializeApp(
          {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId:
              process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
          },
          "Secondary"
        );
      }

      firebase.apps[1]
        .auth()
        .createUserWithEmailAndPassword(
          usingUserName ? `${userFields.mail}@sdy.com` : userFields.mail,
          password
        )
        .then((response) => {
          collection
            .doc(response.user.uid)
            .set({
              ...userFields,
              mail: usingUserName
                ? `${userFields.mail}@sdy.com`
                : userFields.mail,
              password: usingUserName ? password : "-",
              time: new Date(),
              id: response.user.uid,
            })
            .then(() => {
              setLoading(false);
              setUserFields({
                firstName: "",
                lastName: "",
                displayName: "",
                phone: "",
                mail: "",
                role: "-",
                avatarUrl: "",
                center: "-",
              });
              props.onClose();
              secondaryApp.auth().signOut();
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        });
    }
  };

  const removeAvatar = () => {
    setAvatar();
    setUserFields({ ...userFields, avatarUrl: "" });
  };

  const passwordValidate = () => {
    if (!password) return false;
    return !/^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/.test(password);
  };

  const emailValidate = () => {
    const e = userFields.mail;
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!e) return false;
    setUsingUsername(!re.test(String(e).toLocaleLowerCase()));

    // return !re.test(String(e).toLocaleLowerCase()) ? `${e}@sederyom.com` : e;
    // setErrors({ ...errors, mail: !re.test(String(e).toLocaleLowerCase()) });
  };

  const handleAvatarChange = (event) => {
    if (!event) {
      return;
    }
    const files = event.target.files;
    if (!files) {
      return;
    }
    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/svg+xml",
    ];
    if (!fileTypes.includes(avatar.type)) {
      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      return;
    }

    setAvatar(avatar);
    setLoadingAvatar(true);
    storage
      .ref(`/avatars/${moment().unix()}/${avatar}`)
      .put(avatar)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadUrl) => {
          setUserFields({
            ...userFields,
            avatarUrl: downloadUrl,
          });
          setLoadingAvatar(false);
        });
      });
  };

  const onTextFieldChange = (event) => {
    setUserFields({
      ...userFields,
      [event.target.id || event.target.name]: event.target.value,
    });
    if (event.target.value) {
      setErrors({
        ...errors,
        [event.target.id]: userFields[event.target.id] === "",
      });
    }
  };
  const deleteUser = () => {
    firestore
      .collection("users")
      .doc(props.content.id)
      .delete()
      .then(() => props.onClose());
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onBackdropClick={props.onClose}>
        <DialogTitle component="div">
          <Typography variant="h6" component="div">
            {props.content ? "עדכון משתמש" : "יצירת משתמש"}
          </Typography>
          <Tooltip title="Close">
            <IconButton className={classes.closeButton} onClick={props.onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div className={classes.loadingDiv}>
              <CircularProgress />
            </div>
          ) : (
            <form>
              <TextField
                id="firstName"
                label="שם פרטי"
                InputLabelProps={{ className: classes.inputLabel }}
                value={userFields.firstName}
                required
                error={!!errors.firstName}
                className={classes.textField}
                onChange={onTextFieldChange}
              />
              <TextField
                id="lastName"
                label="שם משפחה"
                InputLabelProps={{ className: classes.inputLabel }}
                value={userFields.lastName}
                required
                className={classes.textField}
                onChange={onTextFieldChange}
              />
              <TextField
                id="displayName"
                label="שם משתמש"
                InputLabelProps={{ className: classes.inputLabel }}
                value={userFields.displayName}
                required
                className={classes.textField}
                onChange={onTextFieldChange}
              />
              <TextField
                id="phone"
                label="טלפון"
                InputLabelProps={{ className: classes.inputLabel }}
                value={userFields.phone}
                required
                type={"number"}
                className={classes.textField}
                onChange={onTextFieldChange}
              />
              <TextField
                id="mail"
                label="דואר אלקטרוני"
                InputLabelProps={{ className: classes.inputLabel }}
                value={userFields.mail}
                required
                className={classes.textField}
                onChange={onTextFieldChange}
                error={errors.mail}
                onBlur={emailValidate}
              />
              {!props.content && (
                <TextField
                  id="password"
                  label="סיסמא"
                  InputLabelProps={{ className: classes.inputLabel }}
                  value={password}
                  required
                  className={classes.textField}
                  onChange={(event) => setPassword(event.target.value)}
                  helperText={
                    "הסיסמא צריכה להיות באורך של לפחות 6 תוים ולהכיל אות ומספר"
                  }
                  error={passwordValidate()}
                />
              )}
              <FormControl id="role" className={classes.formControl}>
                <InputLabel
                  id="select-role-label"
                  className={classes.inputLabel}
                >
                  הרשאות
                </InputLabel>
                <Select
                  labelId="select-role-label"
                  id="role"
                  name="role"
                  value={userFields.role || "-"}
                  onChange={onTextFieldChange}
                >
                  <MenuItem value="-">משתמש</MenuItem>
                  <MenuItem value={"admin"}>מנהל</MenuItem>
                </Select>
              </FormControl>
              {userFields.role === "-" && (
                <FormControl id="center" className={classes.formControl}>
                  <InputLabel
                    id="select-center-label"
                    className={classes.inputLabel}
                  >
                    מרכז
                  </InputLabel>
                  <Select
                    labelId="select-center-label"
                    id="center"
                    name="center"
                    value={userFields.center}
                    onChange={onTextFieldChange}
                  >
                    {centers.map((center, index) => (
                      <MenuItem key={index} value={center.value}>
                        {center.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <Box textAlign="center" className={classes.imageBox} mb={3}>
                {!avatar && !userFields.avatarUrl && (
                  <>
                    <input
                      id="avatar-input"
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleAvatarChange}
                    />

                    <label htmlFor="avatar-input">
                      <Button
                        color="primary"
                        component="span"
                        disabled={performingAction}
                        variant="outlined"
                      >
                        בחירה
                      </Button>
                    </label>
                  </>
                )}

                <Box mb={1.5}>
                  {avatar && userFields.avatarUrl && (
                    <Badge
                      classes={{ badge: classes.badge }}
                      badgeContent={
                        <Tooltip title="הסרה">
                          <Fab
                            classes={{ sizeSmall: classes.small }}
                            color="secondary"
                            disabled={performingAction}
                            size="small"
                            onClick={removeAvatar}
                          >
                            <Close fontSize="small" />
                          </Fab>
                        </Tooltip>
                      }
                    >
                      <Avatar
                        className={classes.avatar}
                        alt="Avatar"
                        src={userFields.avatarUrl}
                      />
                      {loadingAvatar && (
                        <Badge
                          classes={{ badge: classes.loadingBadge }}
                          badgeContent={
                            <Fade
                              style={{ transitionDelay: "1s" }}
                              in={loadingAvatar}
                              unmountOnExit
                            >
                              <CircularProgress size={120} thickness={1.8} />
                            </Fade>
                          }
                        ></Badge>
                      )}{" "}
                    </Badge>
                  )}

                  {!userFields.avatarUrl && (
                    <>
                      {userFields.avatarUrl && (
                        <Badge
                          classes={{ badge: classes.badge }}
                          badgeContent={
                            <Tooltip title="Remove">
                              <Fab
                                classes={{ sizeSmall: classes.small }}
                                color="secondary"
                                disabled={performingAction}
                                size="small"
                                onClick={removeAvatar}
                              >
                                <Close fontSize="small" />
                              </Fab>
                            </Tooltip>
                          }
                        >
                          {!loadingAvatar && (
                            <Badge
                              classes={{ badge: classes.loadingBadge }}
                              badgeContent={
                                <CircularProgress size={120} thickness={1.8} />
                              }
                            >
                              <Avatar
                                className={classes.avatar}
                                alt="Avatar"
                                src={userFields.avatarUrl}
                              />
                            </Badge>
                          )}

                          {!loadingAvatar && (
                            <Avatar
                              className={classes.avatar}
                              alt="Avatar"
                              src={userFields.avatarUrl}
                            />
                          )}
                        </Badge>
                      )}

                      {!userFields.avatarUrl && (
                        <>
                          {loadingAvatar && (
                            <Badge
                              classes={{ badge: classes.loadingBadge }}
                              badgeContent={
                                <Fade
                                  style={{ transitionDelay: "1s" }}
                                  in={loadingAvatar}
                                  unmountOnExit
                                >
                                  <CircularProgress
                                    size={120}
                                    thickness={1.8}
                                  />
                                </Fade>
                              }
                            >
                              <Avatar className={classes.avatar} alt="Avatar">
                                <Person className={classes.personIcon} />
                              </Avatar>
                            </Badge>
                          )}

                          {!loadingAvatar && (
                            <Avatar className={classes.avatar} alt="Avatar">
                              <Person className={classes.personIcon} />{" "}
                            </Avatar>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          {props.content && (
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={deleteUser}
            >
              הסרה
            </Button>
          )}
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSave}
            disabled={
              !userFields.firstName ||
              !userFields.lastName ||
              !userFields.phone ||
              !userFields.displayName ||
              !userFields.mail ||
              (!props.content && !password)
            }
          >
            {props.content ? "עדכון משתמש" : "יצירת משתמש"}
          </Button>
          <Button color="secondary" onClick={props.onClose}>
            חזרה
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

AddUserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  content: PropTypes.object,
};
