import React from "react";
import PropTypes from "prop-types";

import { Box, Typography } from "@material-ui/core";
import amiLogo from "../Assets/amiLogo.png";
import cognitiveLogo from "../Assets/cognitiveLogo.png";
function EmptyState(props) {
  let imageWidth;
  let imageHeight;
  let variant;

  switch (props.size) {
    case "small":
      imageWidth = 40;
      imageHeight = 40;
      variant = "h6";
      break;

    case "medium":
      imageWidth = 60;
      imageHeight = 60;
      variant = "h5";
      break;

    case "large":
      imageWidth = 100;
      imageHeight = 100;
      variant = "h4";
      break;

    default:
      imageWidth = 60;
      imageHeight = 60;
      variant = "h5";
      break;
  }

  if (props.type === "page") {
    return (
      <Box textAlign="center" width="auto" height="auto">
        {props.button && props.button}
        {props.image && (
          <header id="header">
            <Box
              style={{
                margin: 10,
              }}
            >
              <img
                alt={"amiLogo"}
                src={amiLogo}
                style={{
                  maxWidth: "25vw",
                  maxHeight: 95,
                  marginLeft: 50,
                }}
              />
              <img
                alt={"cognitiveLogo"}
                src={cognitiveLogo}
                style={{ maxWidth: "25vw", maxHeight: 95 }}
              />
            </Box>
            <div className="intro">
              <div className="overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 intro-text">
                      <h1>
                        {props.title ? props.title : "Loading"}
                        <span></span>
                      </h1>
                      <h2>
                        {props.description ? props.description : "Loading"}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}

        {props.team && (
          <div id="services" className="text-center">
            <div className="container">
              <div className="col-md-8 col-md-offset-2 section-title">
                <h2>צוות</h2>
                <p>הצוות המסור שיחד יצר את סדר יום</p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                {props.team.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      <img
                        src={d.img}
                        alt="..."
                        className="team-img"
                        style={{ borderRadius: 150, width: 100, height: 100 }}
                      />
                      <div className="caption">
                        <a href={d.href}>
                          <h4>{d.name}</h4>
                          <p>{d.job}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {props.features && (
          <div id="features" className="text-center">
            <div className="container">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  textAlign: "start",
                  maxWidth: 800,
                  marginLeft: 50,
                  marginRight: 50,
                }}
                className="col-md-1 col-md-offset-1 "
              >
                <h2>מערכת "הכל בסדר", נעים מאוד!</h2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  textAlign: "start",
                  maxWidth: 800,
                  marginLeft: 50,
                  marginRight: 50,
                }}
              >
                {props.features.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))}
                <h1>
                  כי אחרי שיש סדר,
                  <br />
                  הכל בסדר!
                </h1>
              </div>
            </div>
          </div>
        )}
        {props.footer && (
          <Box
            clone
            // width={`${imageWidth}%`}
            // height={`${imageHeight}%`}
          >
            {props.footer}
          </Box>
        )}
      </Box>
    );
  }

  if (props.type === "card") {
    return (
      <Box padding={props.padding} textAlign="center">
        {props.image && (
          <Box
            clone
            mb={props.title || props.description ? 2 : 0}
            width={`${imageWidth}%`}
            height={`${imageHeight}%`}
          >
            {props.image}
          </Box>
        )}

        {props.title && (
          <Box mb={!props.description && props.button ? 2 : 0}>
            <Typography variant={variant}>{props.title}</Typography>
          </Box>
        )}

        {props.description && (
          <Box mb={props.button && 2}>
            <Typography variant="h4">{props.description}</Typography>
          </Box>
        )}

        {props.button && props.button}
      </Box>
    );
  }

  return null;
}

EmptyState.defaultProps = {
  type: "page",
  size: "medium",
  padding: 2,
};

EmptyState.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  padding: PropTypes.number,

  image: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.element,
};

export default EmptyState;
