import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Close } from "@material-ui/icons";

import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Tooltip,
} from "@material-ui/core";

import { HelpOutline } from "@material-ui/icons";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "56%",
  },
  helpButton: {
    position: "absolute",
    left: 10,
    top: 7,
  },
}));

export default function HelpDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Tooltip title="עזרה">
        <IconButton
          className={classes.helpButton}
          onClick={() => setOpen(true)}
          style={props.buttonStyle || {}}
        >
          <HelpOutline />
          {props.title && <h6>{props.title}</h6>}
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen dir={"ltr"}>
        <AppBar color={"primary"}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          style={{ padding: 10, paddingTop: 75, width: "100%", height: "100%" }}
        >
          <iframe
            title={props.videoUrl}
            width="100%"
            height="80%"
            src={props.videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

HelpDialog.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  buttonStyle: PropTypes.object,
};
