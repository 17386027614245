import React from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
} from "@material-ui/core";

import { Message } from "@material-ui/icons";
import moment from "moment";
import { firestore } from "../../firebase";
import Skeleton from "@material-ui/lab/Skeleton";
import appearance from "../../services/appearance";
import Footer from "../Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    minHeight: "84vh",
  },
  currentActivityCard: {
    position: "relative",
    margin: 5,
  },

  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    background: "white",
  },
  iconDiv: {
    position: "relative",
    margin: theme.spacing(4),
    width: "fit-content",
    marginLeft: "auto",
  },
  img: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  progress: {
    position: "absolute",
    top: -theme.spacing(3.5),
    left: -theme.spacing(3.5),
  },
  circle: {
    strokeLinecap: "round",
  },
  messageDiv: {
    position: "absolute",
    bottom: 10,
  },
  card: {
    borderRadius: 200,
    width: 150,
    margin: "auto",
    padding: 10,
  },
  cardContent: {
    background: theme.palette.primary,
  },
}));

export default function PresentCenter(props) {
  const hoursToSubtract = 0;
  const classes = useStyles();
  const { centerId } = useParams();
  const [currentEvent] = React.useState();
  const [todaysEvents, setTodaysEvents] = React.useState([]);
  // const [repeatingEvents, setRepeatingEvents] = React.useState();
  const [activeClasses, setActiveClasses] = React.useState([]);
  const [centerClasses, setClasses] = React.useState({});
  const [now, setNow] = React.useState(
    moment().subtract(hoursToSubtract, "hours").format()
  );

  React.useEffect(() => {
    // fetchRepeatingEvents();
    fetchTodaysEvents();
    firestore
      .collection("classes")
      .where("centerId", "==", centerId)
      .get()
      .then((snapshotQuery) => {
        snapshotQuery.forEach((snapshot) => {
          centerClasses[snapshot.data().id] = snapshot.data();
        });
      });
    setClasses(centerClasses);
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    progress();
    //eslint-disable-next-line
  }, [todaysEvents]);

  React.useEffect(() => {
    progress();
    //eslint-disable-next-line
  }, [now]);

  React.useEffect(() => {
    const timer = setInterval(
      () => setNow(moment().subtract(hoursToSubtract, "hours").format()),
      1000
    ); // clearing interval
    return () => clearInterval(timer);
  }, []);

  const fetchTodaysEvents = () => {
    if (!now) return;
    firestore
      .collection("events")
      .where("centerId", "==", centerId)
      .where("date", "==", now.split("T")[0])
      .get()
      .then((querySnapshot) => {
        var events = [];
        querySnapshot.forEach((snapshot) => {
          events.push(snapshot.data());
        });

        events && setTodaysEvents(events);
      });
  };

  // const fetchRepeatingEvents = () => {
  //   if (!now) return;
  //   var today = moment(now, moment.ISO_8601).day();
  //   firestore
  //     .collection("events")
  //     .where("centerId", "==", centerId)
  //     .where("endRecur", ">=", now)
  //     .get()
  //     .then((querySnapshot) => {
  //       var rEvents = [];
  //       querySnapshot.forEach((snapshot) => {
  //         if (
  //           snapshot.data().startRecur > now &&
  //           snapshot.data().daysOfWeek.includes(today + "")
  //         ) {
  //           rEvents.push(snapshot.data());
  //         }
  //       });
  //       setRepeatingEvents(rEvents);
  //     });
  // };

  const progress = () => {
    // trigger next day

    if (currentEvent) {
      // const n = moment(now.split("T")[1].substring(0, 8), "hh:mm:ss");
      // const s = moment(
      //   currentEvent.start.split("T")[1].substring(0, 8),
      //   "hh:mm:ss"
      // );
      // const e = moment(
      //   currentEvent.end.split("T")[1].substring(0, 8),
      //   "hh:mm:ss"
      // );
      // const prog = s.diff(n, "seconds");
      // const dur = s.diff(e, "seconds");
      // setClassProgress((prog / dur) * 100 > 100 ? 100 : (prog / dur) * 100);
    }
    var activeClasses = [];
    if (todaysEvents.length) {
      todaysEvents.forEach((event, index) => {
        if (
          event.start.split("T")[1] < now.split("T")[1] &&
          event.end.split("T")[1] > now.split("T")[1]
        ) {
          activeClasses.push(event);
        }
      });
    }
    setNow(moment().subtract(hoursToSubtract, "hours").format());
    setActiveClasses(activeClasses);
  };

  return (
    <>
      <Paper className={classes.root}>
        <Grid container>
          {activeClasses.length === 0 && (
            <Typography variant={"body1"}>
              לא נמצאו כיתות פעילות במרכז
            </Typography>
          )}
          {activeClasses.map((currentEvent, index) => (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <Card className={classes.currentActivityCard}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography variant="subtitle1">
                        {currentEvent ? currentEvent.title : <Skeleton />}
                      </Typography>
                      <Typography variant="subtitle1">
                        {currentEvent ? currentEvent.description : <Skeleton />}
                      </Typography>
                      <Typography variant="subtitle1">
                        {currentEvent ? (
                          `${moment(currentEvent.start, moment.ISO_8601).format(
                            "HH:mm"
                          )} - ${moment(
                            currentEvent.end,
                            moment.ISO_8601
                          ).format("HH:mm")}`
                        ) : (
                          <Skeleton />
                        )}
                      </Typography>
                      {currentEvent && currentEvent.comment && (
                        <div className={classes.messageDiv}>
                          <Message color={"primary"} />
                          <Typography variant="body1">
                            {currentEvent.comment}
                          </Typography>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Card
                        className={classes.card}
                        style={{
                          background:
                            centerClasses[currentEvent.classId]?.color &&
                            appearance?.colors[
                              centerClasses[currentEvent.classId]?.color
                            ]
                              ? appearance?.colors[
                                  centerClasses[currentEvent.classId]?.color
                                ].import[500]
                              : "grey",
                        }}
                      >
                        <CardContent className={classes.cardContent}>
                          <div className={classes.classTitleWrapper}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                              className={classes.classTitle}
                            >
                              {centerClasses[currentEvent.classId]?.nickname}
                            </Typography>
                          </div>
                          {currentEvent ? (
                            <Avatar className={classes.avatar}>
                              <img
                                alt={"avatar"}
                                src={currentEvent.icon}
                                className={classes.img}
                              />
                            </Avatar>
                          ) : (
                            <Skeleton
                              variant={"circle"}
                              width={100}
                              height={100}
                            />
                          )}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Footer />
    </>
  );
}

PresentCenter.propTypes = {};
