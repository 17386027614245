import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import GenericWeather from "../GenericWeather";

class OpenWeatherMap extends React.Component {
  constructor() {
    super();
    this.state = {
      name: null,
      temp: null,
      time: "",
    };
  }

  componentDidMount() {
    const { city, country, time, appid, units } = this.props;

    axios
      .get("https://api.openweathermap.org/data/2.5/weather", {
        params: {
          q: `${city},${country}`,
          APPID: appid,
          units,
        },
      })
      .then((response) => {
        const { data } = response;
        this.setState({
          name: "באר שבע",
          temp: Math.round(data.main.temp),
          time: time,
        });
      });
  }

  render() {
    const { name, temp, time } = this.state;
    return <GenericWeather city={name} temp={temp} time={time} />;
  }
}

OpenWeatherMap.propTypes = {
  city: PropTypes.string.isRequired,
  country: PropTypes.string,
  time: PropTypes.string,
  appid: PropTypes.string.isRequired,
  units: PropTypes.string,
};

OpenWeatherMap.defaultProps = {
  city: "Jerusalem",
  country: "IL",
  time: "",
  appid: "",
  units: "metric",
};

export default OpenWeatherMap;
