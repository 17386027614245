import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Close } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import { storage } from "../../firebase";
const useStyles = makeStyles(() => ({
  root: {
    minWidth: 500,
    minHeight: 400,
  },
  textField: {
    width: "100%",
    padding: 10,
  },
  formControl: {
    width: "100%",
    padding: 10,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  loadingDiv: {
    minWidth: 500,
    minHeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    paddingLeft: 15,
  },
  img: {
    width: 50,
    height: 50,
    padding: 5,
  },
}));

export default function IconSelectDialog(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [icons, setIcons] = React.useState([]);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    setLoading(true);
    const icons = [];
    storage
      .ref()
      .child("iconsCohen")
      .listAll()
      .then((res) => {
        res.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            icons.push(url);
          });
        });
      })
      .then(() => {
        icons.sort();
        setIcons(icons);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [props.open]);

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onBackdropClick={() => props.onClose()}
        className={classes.root}
      >
        <DialogTitle>
          <Typography variant="h6" component="div">
            בחירת סמל
          </Typography>
          <Tooltip title="Close">
            <IconButton
              className={classes.closeButton}
              onClick={() => props.onClose()}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div className={classes.loadingDiv}>
              <CircularProgress />
            </div>
          ) : (
            <Grid container>
              {icons.slice(page * 97, (page + 1) * 97 - 1).map((item) => (
                <Grid item xs={1} key={item}>
                  <Button onClick={() => props.onClose(item)}>
                    <img alt={item} src={item} className={classes.img} />
                  </Button>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Pagination
            count={Math.ceil(icons.length / 100)}
            color={"secondary"}
            onChange={(_event, page) => setPage(page)}
          />
          <div style={{ marginLeft: "auto" }} />
          <Button color="primary" onClick={props.onClose}>
            חזרה
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

IconSelectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
