import React from "react";
import cx from "classnames";
import "./GenericWeather.scss";

function GenericWeather({ city, time, temp, status }) {
  const cls = cx("weather-icon", status);
  return (
    <div className="weather-card">
      <h1>{temp}º</h1>
      <div className={cls} />
    </div>
  );
}

// GenericWeather.propTypes = {
//   city: PropTypes.string,
//   temp: PropTypes.number,
//   status: PropTypes.string,
// };

GenericWeather.defaultProps = {
  city: "Jerusalem",
  temp: "25º",
  status: "sun",
};

export default GenericWeather;
