import React, { Component } from "react";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Avatar,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import { ChevronLeft, ScreenShare } from "@material-ui/icons";

// import UserAvatar from "../UserAvatar";
import { auth, firestore } from "../../firebase";
import AmiLogo from "../Assets/logo512.png";

import { unregister } from "../../serviceWorker";
// import recoverDeletedClasses from "../../onOffs/recoverDeletedClasses";

const APP_VERSION =
  "0.25" > process.env.REACT_APP_VERSION
    ? "0.25"
    : process.env.REACT_APP_VERSION;

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
      route: this.props.location,
      refresh: false,
      centerTitle: "",
      classTitle: "",
    };
  }

  componentDidMount = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        firestore
          .collection("users")
          .doc(user.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.data().role === "admin") {
              this.props.history.push("/");
            } else {
              this.props.history.push(`/center/${snapshot.data().center}`);
            }
          });
      } else {
        this.props.history.push("/");
      }
    });

    firestore
      .collection("system")
      .doc("version")
      .get()
      .then((snapshot) =>
        this.setState({
          refresh: snapshot.data().current !== APP_VERSION,
        })
      );
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const centerId = this.props.location.pathname.split("/")[2];
      const classId = this.props.location.pathname.split("/")[3];
      if (centerId) {
        firestore
          .collection("centers")
          .doc(centerId)
          .get()
          .then((snapshot) => {
            if (snapshot.data()) {
              this.setState({ centerTitle: snapshot.data().name });
            }
          });
      } else {
        this.setState({ centerTitle: "" });
      }

      if (classId) {
        firestore
          .collection("classes")
          .doc(classId)
          .get()
          .then((snapshot) => {
            snapshot.data() &&
              this.setState({ classTitle: snapshot.data().nickname });
          });
      } else {
        this.setState({ classTitle: "" });
      }
    }
  };
  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl,
      },
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null,
      },
    });
  };

  render() {
    // Properties
    const {
      performingAction,
      user,
      //userData,
      roles,
    } = this.props;

    // Events
    const {
      onAboutClick,
      onSettingsClick,
      onSignOutClick,
      // onSignUpClick,
      onSignInClick,
    } = this.props;

    const { menu } = this.state;

    const menuItems = [
      {
        name: "אודות",
        onClick: onAboutClick,
      },
      {
        name: "ניהול משתמשים",
        to: user ? `/admin` : null,
      },
      {
        name: "הגדרות",
        onClick: onSettingsClick,
      },
      {
        name: "התנתק",
        divide: true,
        onClick: onSignOutClick,
      },
    ];

    return (
      <AppBar color="primary" position="static">
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <small>{APP_VERSION}</small>
            <Typography color="inherit" variant="h6">
              {process.env.REACT_APP_TITLE}
              {this.state.centerTitle && `  - ${this.state.centerTitle}`}
              {this.state.classTitle && ` - כיתה ${this.state.classTitle}`}
            </Typography>
            {this.state.refresh && (
              <Alert
                style={{ marginRight: 20 }}
                severity="warning"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      caches
                        .keys()
                        .then((cs) => cs.forEach((c) => caches.delete(c)));
                      unregister();
                    }}
                  >
                    רענון
                  </Button>
                }
              >
                קיימת גרסא חדשה של האפליקציה
              </Alert>
            )}
          </Box>
          {user && (
            <>
              {roles.includes("admin") ? (
                <Box mr={1}>
                  <>
                    {this.props.location.pathname.includes("center") &&
                      !this.props.location.pathname.includes(
                        "center_present"
                      ) && (
                        <Button
                          color="inherit"
                          component={Link}
                          to={`/center/${
                            this.props.location.pathname.split("/")[2]
                          }/center_present`}
                          variant="outlined"
                          style={{ margin: 10 }}
                        >
                          <ScreenShare />
                          הצגת מרכז
                        </Button>
                      )}

                    {(this.props.location.pathname.includes("center") ||
                      this.props.location.pathname.includes("admin") ||
                      this.props.location.pathname.includes("class")) && (
                      <Button
                        color="inherit"
                        component={Link}
                        to="/"
                        variant="outlined"
                        style={{ margin: 10 }}
                      >
                        מרכזים
                        <ChevronLeft />
                      </Button>
                    )}
                    {this.props.location.pathname.includes("class") && (
                      <Button
                        color="inherit"
                        component={Link}
                        to={`/center/${
                          this.props.location.pathname.split("/")[2]
                        }`}
                        variant="outlined"
                        style={{ margin: 10 }}
                      >
                        כיתות
                        <ChevronLeft />
                      </Button>
                    )}
                  </>
                </Box>
              ) : (
                <>
                  {" "}
                  {this.props.location.pathname.includes("class") && (
                    <Button
                      color="inherit"
                      component={Link}
                      to={`/center/${
                        this.props.location.pathname.split("/")[2]
                      }`}
                      variant="outlined"
                      style={{ margin: 10 }}
                    >
                      כיתות
                      <ChevronLeft />
                    </Button>
                  )}
                </>
              )}

              <IconButton
                color="inherit"
                disabled={performingAction}
                onClick={this.openMenu}
              >
                <Avatar alt="Avatar" src={AmiLogo} />
                {/* <UserAvatar user={userData} /> */}
              </IconButton>

              <Menu
                anchorEl={menu.anchorEl}
                open={Boolean(menu.anchorEl)}
                onClose={this.closeMenu}
              >
                {menuItems.map((menuItem, index) => {
                  if (
                    menuItem.hasOwnProperty("condition") &&
                    !menuItem.condition
                  ) {
                    return null;
                  }

                  let component = null;

                  if (menuItem.to) {
                    component = (
                      <MenuItem
                        key={index}
                        component={Link}
                        to={menuItem.to}
                        onClick={this.closeMenu}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  } else {
                    component = (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          this.closeMenu();

                          menuItem.onClick();
                        }}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  }

                  if (menuItem.divide) {
                    return (
                      <span key={index}>
                        <Divider />

                        {component}
                      </span>
                    );
                  }

                  return component;
                })}
              </Menu>
            </>
          )}

          {!user && (
            <ButtonGroup
              color="inherit"
              disabled={performingAction}
              variant="outlined"
            >
              {/* <Button onClick={onSignUpClick}>Sign up</Button> */}
              <Button onClick={onSignInClick}>התחברות</Button>
            </ButtonGroup>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default withRouter(Bar);
