import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { auth, firestore } from "../../firebase";
import HelpDialog from "../HelpDialog";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    padding: 10,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  loadingDiv: {
    minWidth: 500,
    minHeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  deleteButton: {
    background: "red",
    color: "white",
    marginRight: "auto",
  },
}));

export default function CenterDialog(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [centerFields, setCenterFields] = React.useState({
    name: "",
    phone: "",
    address: "",
    email: "",
    comment: "",
    defaultEndOfYear: "",
    defaultClassLength: "",
  });

  React.useEffect(() => {
    if (props.center) {
      setLoading(true);
      setCenterFields(props.center);
      setLoading(false);
    }
  }, [props.center]);

  const handleSave = () => {
    setLoading(true);
    const collection = firestore.collection("centers");

    if (props.center && props.center.id) {
      collection
        .doc(props.center.id)
        .update({ ...centerFields })
        .then(() => {
          setLoading(false);
          props.onClose();
          // window.location.reload();
        });
    } else {
      collection
        .add({ ...centerFields })
        .then((docRef) => {
          collection.doc(docRef.id).update({ time: new Date(), id: docRef.id });
          setLoading(false);
          props.onClose();
          // window.location.reload();
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    }
  };

  const deleteEvent = () => {
    firestore
      .collection("centers")
      .doc(props.center.id)
      .delete()
      .then(() => {
        initializeForm();
        props.onClose();
        // window.location.reload();
      });
  };

  const initializeForm = () => {
    setCenterFields({
      name: "",
      phone: "",
      address: "",
      email: "",
      comment: "",
      defaultClassLength: "",
      defaultEndOfYear: "",
    });
  };
  return (
    <React.Fragment>
      <Dialog open={props.open} onBackdropClick={props.onClose}>
        <DialogTitle>
          <Typography variant="h6" component="div">
            הגדרת מרכז
          </Typography>
          <Tooltip title="Close">
            <IconButton className={classes.closeButton} onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <HelpDialog videoUrl={""} />
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div className={classes.loadingDiv}>
              <CircularProgress />
            </div>
          ) : (
            <form>
              <TextField
                id="name"
                label="שם המרכז"
                value={centerFields.name}
                required
                className={classes.textField}
                onChange={(event) =>
                  setCenterFields({
                    ...centerFields,
                    name: event.target.value,
                    createdBy: auth.currentUser.email,
                  })
                }
              />
              <TextField
                id="address"
                label="כתובת"
                value={centerFields.address}
                className={classes.textField}
                onChange={(event) =>
                  setCenterFields({
                    ...centerFields,
                    address: event.target.value,
                  })
                }
              />
              <TextField
                id="phone"
                label="טלפון"
                value={centerFields.phone}
                type="tel"
                required
                className={classes.textField}
                onChange={(event) =>
                  setCenterFields({
                    ...centerFields,
                    phone: event.target.value,
                  })
                }
              />
              <TextField
                id="email"
                label="דוא״ל"
                type="email"
                value={centerFields.email}
                required
                className={classes.textField}
                onChange={(event) =>
                  setCenterFields({
                    ...centerFields,
                    email: event.target.value,
                  })
                }
              />
              <TextField
                id="comment"
                label="הערות"
                value={centerFields.comment}
                className={classes.textField}
                onChange={(event) =>
                  setCenterFields({
                    ...centerFields,
                    comment: event.target.value,
                  })
                }
              />
              <Autocomplete
                id="defaultClassLength"
                options={[
                  {
                    title: "חצי שעה",
                    value: "30",
                  },
                  { title: "45 דקות", value: "45" },
                  {
                    title: "שעה",
                    value: "60",
                  },
                  {
                    title: "שעתיים",
                    value: "120",
                  },
                ]}
                onChange={(_event, value) => {
                  setCenterFields({
                    ...centerFields,
                    defaultClassLength: value.value,
                  });
                }}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="אורך שיעור"
                    value={centerFields.defaultClassLength}
                    className={classes.textField}
                    helperText="ברירת מחדל למשך שיעור"
                  />
                )}
              />
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                locale={"he"}
              >
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YY"
                  margin="normal"
                  id="date"
                  label="תאריך סוף שנה"
                  value={centerFields.defaultEndOfYear}
                  className={classes.textField}
                  onChange={(date) =>
                    setCenterFields({
                      ...centerFields,
                      defaultEndOfYear: date.format("DD/MM/YYYY"),
                    })
                  }
                />
              </MuiPickersUtilsProvider>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          {props.center && (
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={deleteEvent}
            >
              הסרה
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={
              !centerFields.name || !centerFields.email || !centerFields.phone
            }
          >
            {props.center ? "עדכון פרטי מרכז" : "יצירת מרכז"}
          </Button>
          <Button color="primary" onClick={props.onClose}>
            חזרה
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

CenterDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  center: PropTypes.object,
};
