import React from "react";
import { useParams, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import ClassDialog from "../ClassDialog/ClassDialog";
import appearance from "../../services/appearance";
import Footer from "../Footer";

import {
  Container,
  Typography,
  Grid,
  Button,
  CardMedia,
  CardContent,
  Card,
  Paper,
  LinearProgress,
  CardActions,
} from "@material-ui/core";

import { Settings, CalendarToday } from "@material-ui/icons";
import { firestore } from "../../firebase";
import HelpDialog from "../HelpDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "84vh",
    background: "rgba(200, 200, 200, 0.5)",
  },
  card: {
    borderRadius: 200,
    width: 150,
    height: 150,
    margin: "auto",
    padding: 10,
  },
  cardContent: {
    background: theme.palette.primary,
  },
  cardMedia: {},
  icon: {
    color: theme.palette.secondary,
  },
  classGrid: {
    display: "flex",
    flexDirection: "column",
  },
  classTitleWrapper: {
    backgroundColor: "white",
    width: 100,
    height: 100,
    borderRadius: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  classTitle: {
    color: "black",
    width: 80,
    textAlign: "center",
    linkDecoration: "none",
  },
  progressBar: {
    width: "100%",
  },
  placeHolder: {
    padding: 10,
  },
  cardActions: {
    justifyContent: "center",
  },
}));

export default function ClassSelect(props) {
  const classes = useStyles();
  const { centerId } = useParams();
  const [classList, setClassList] = React.useState([]);
  const [classDialogOpen, setClassDialogOpen] = React.useState(false);
  const [loadingClasses, setLoadingClasses] = React.useState(false);
  const [selectedClass, setSelectedClass] = React.useState();

  React.useEffect(() => {
    var classList = [];
    setLoadingClasses(true);
    firestore
      .collection("classes")
      .where("centerId", "==", centerId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          classList.push(doc.data());
        });
      })
      .then(() => {
        setLoadingClasses(false);
        setClassList(classList);
      });
  }, [centerId, classDialogOpen]);

  const onClassDialogClose = (_event) => {
    setClassDialogOpen(false);
  };

  return (
    <>
      <ClassDialog
        onClose={onClassDialogClose}
        open={classDialogOpen}
        selectedClass={selectedClass}
      />
      <Paper className={classes.root}>
        {loadingClasses ? (
          <LinearProgress color="primary" className={classes.progressBar} />
        ) : (
          <div className={classes.placeHolder} />
        )}

        <Container className={classes.cardGrid} maxWidth="md">
          <HelpDialog
            videoUrl={""}
            buttonStyle={{ position: "absolute", top: 65, left: 0 }}
          />
          <Grid container spacing={4} style={{ paddingTop: 30 }}>
            {classList.map((sClass) => (
              <Grid
                item
                key={sClass.id}
                xs={12}
                sm={6}
                md={4}
                className={classes.classGrid}
              >
                <Card
                  className={classes.card}
                  style={{
                    background: appearance.colors[sClass.color]
                      ? appearance.colors[sClass.color].import[500]
                      : "grey",
                  }}
                >
                  <CardContent
                    className={classes.cardContent}
                    component={Link}
                    to={`/class/${sClass.centerId}/${sClass.id}/present`}
                  >
                    <div className={classes.classTitleWrapper}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        className={classes.classTitle}
                      >
                        {sClass.nickname}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
                <CardActions className={classes.cardActions}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setClassDialogOpen(true);
                      setSelectedClass(sClass.id);
                    }}
                    to={`/class/${sClass.centerId}/${sClass.id}`}
                  >
                    <Settings className={classes.icon} />
                  </Button>
                  <Button
                    color="secondary"
                    component={Link}
                    to={`/class/${sClass.centerId}/${sClass.id}`}
                  >
                    <CalendarToday className={classes.icon} />
                  </Button>
                </CardActions>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4}>
              <Card
                className={classes.card}
                onClick={() => setClassDialogOpen(true)}
              >
                <CardMedia
                  className={classes.cardMedia}
                  image="https://source.unsplash.com/bj3l739cwc8"
                  title="Add center image by @kellysikkema"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" align="center">
                    הוספת כיתה
                  </Typography>
                  <Typography variant="h5" align="center">
                    +
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Footer />
    </>
  );
}

ClassSelect.propTypes = {
  // updatedSelectedClass: PropTypes.func.isRequired,
};
