import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

import { Close, Check, Person } from "@material-ui/icons";

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Tooltip,
  IconButton,
  TextField,
  Button,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  Avatar,
  Chip,
  ListItem,
  ListItemIcon,
  Hidden,
  FormControlLabel,
  Checkbox,
  Badge,
  Fade,
  Fab,
  Box,
  Typography,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import appearance from "../../services/appearance";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/he";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { auth, firestore, storage } from "../../firebase";
import IconSelectDialog from "../IconSelectDialog";
import HelpDialog from "../HelpDialog";
import TeamSelect from "../TeamSelect";

const useStyles = makeStyles((theme) => ({
  titleTextField: {
    width: "100%",
    padding: 10,
  },
  descriptionTextField: {
    width: "100%",
    padding: 10,
  },
  formControl: {
    width: "100%",
    minWidth: 240,
    padding: 10,
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  helpButton: {
    position: "absolute",
    right: 60,
    top: 10,
  },
  loadingDiv: {
    minWidth: 600,
    minHeight: 652,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputLabel: {
    paddingLeft: 15,
    minWidth: 240,
  },
  datePicker: {
    padding: 5,
    marginTop: 15,
    marginBottom: 10,
  },
  avatar: {
    width: 150,
    height: 150,
    margin: 20,
  },
  avatarImage: {
    width: 150,
    height: 150,
  },
  deleteButton: {
    background: "red",
    color: "white",
    marginRight: "auto",
  },
  listAvatar: {
    width: 60,
    height: 60,
    marginRight: 10,
  },
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(14),
  },

  loadingBadge: {
    top: "50%",
    right: "50%",
  },

  birthdayAvatar: {
    marginRight: "auto",
    marginLeft: "auto",

    width: theme.spacing(14),
    height: theme.spacing(14),
  },
}));

export default function EventDialog(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [teamNames, setTeamNames] = React.useState([]);
  const [icons, setIcons] = React.useState([]);
  const [loadingIcons, setLoadingIcons] = React.useState(false);
  const [birthdayAvatar, setBirthdayAvatar] = React.useState();
  const [loadingAvatar, setLoadingAvatar] = React.useState(false);
  const [iconSelectDialogOpen, setIconsSelectDialogOpen] =
    React.useState(false);

  const [eventFields, setEventFields] = React.useState({
    title: "",
    description: "",
    team: [],
    date: moment(),
    startTime: moment(),
    endTime: moment().add(props.defaultClassLength, "minutes"),
    repetition: "weeks",
    startRecur: moment(),
    endRecur: moment(props.defaultEndOfYear, "DD/MM/YYYY"),
    comment: ``,
    icon: "",
    daysOfWeek: [],
    color: "",
    singleComment: false,
    allDayEvent: false,
    singleCommentDate: moment(),
  });
  const { centerId, classId } = useParams();

  React.useEffect(() => {
    setLoading(true);

    if (props.event) {
      setEventFields({
        ...props.event,
        startTime: props.event.start,
        endTime: props.event.end,
        team: props.event.team || "",
      });
      setLoading(false);
    } else {
      initializeForm();
    }

    var names = [];
    firestore
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          names.push(
            `${doc.data().firstName} ${doc.data().lastName},${
              doc.data().avatarUrl
            }`
          );
        });
      })
      .then(() => {
        setTeamNames(names.sort());
      });
    const ic = [];
    storage
      .ref()
      .child("iconsCohen")
      .listAll()
      .then((res) => {
        setLoadingIcons(true);
        res.items.forEach((item) => {
          item
            .getDownloadURL()
            .then((url) => {
              ic.push({
                name: item.name.split(".")[0],
                image: url,
                firstLetter: item.name.split(".")[0][0],
              });
            })
            .then(() => {
              if (res.items.length === ic.length) {
                ic.sort(
                  (a, b) =>
                    parseFloat(a.name.split(")")[0]) -
                    parseFloat(b.name.split(")")[0])
                );
                setIcons(ic);
                setLoadingIcons(false);
              }
            });
        });
      });
    // eslint-disable-next-line
  }, [props.event]);

  const initializeForm = () => {
    setEventFields({
      title: "",
      description: "",
      team: [],
      date: moment(),
      startTime: moment(),
      endTime: moment().add(props.defaultClassLength, "minutes"),
      repetition: "weeks",
      startRecur: moment(),
      endRecur: moment(props.defaultEndOfYear, "DD/MM/YYYY"),
      comment: ``,
      icon: "",
      color: "",
      daysOfWeek: [],
      singleComment: false,
      allDayEvent: false,
      singleCommentDate: moment(),
      birthdayAvatar: "",
    });
    setLoading(false);
  };

  const formatForm = () => {
    if (eventFields.repetition === "") {
      return {
        title: eventFields.title,
        description: eventFields.description,
        comment: eventFields.comment,
        date:
          eventFields.date instanceof moment
            ? eventFields.date.format("YYYY-MM-DD")
            : eventFields.date || moment().format("YYYY-MM-DD"),
        start:
          eventFields.startTime instanceof moment
            ? eventFields.startTime.format()
            : eventFields.start || moment().format(),
        end:
          eventFields.endTime instanceof moment
            ? eventFields.endTime.format()
            : eventFields.end ||
              moment().add(props.defaultClassLength, "minutes").format(),
        startTime:
          eventFields.startTime instanceof moment
            ? eventFields.startTime.format("HH:mm")
            : eventFields.startTime
            ? eventFields.startTime.length > 5
              ? moment(eventFields.startTime, moment.ISO_8601).format("HH:mm")
              : eventFields.startTime
            : moment().add(props.defaultClassLength, "minutes").format("HH:mm"),
        endTime:
          eventFields.endTime instanceof moment
            ? eventFields.endTime.format("HH:mm")
            : eventFields.endTime
            ? eventFields.endTime.length > 5
              ? moment(eventFields.endTime, moment.ISO_8601).format("HH:mm")
              : eventFields.endTime
            : moment().add(props.defaultClassLength, "minutes").format("HH:mm"),
        icon: props.birthday
          ? "https://firebasestorage.googleapis.com/v0/b/sederyommui.appspot.com/o/iconsCohen%2F1051)%D7%99%D7%95%D7%9D%20%D7%94%D7%95%D7%9C%D7%93%D7%AA.png?alt=media&token=7b8492c3-c7c9-4f53-b754-2af739e384a6"
          : eventFields.icon,
        startRecur:
          eventFields.date instanceof moment
            ? eventFields.date.format()
            : eventFields.date || moment().format(),
        endRecur:
          eventFields.date instanceof moment
            ? eventFields.date.add(props.birthday ? 7300 : 1, "day").format()
            : moment(eventFields.date, moment.ISO_8601)
                .add(props.birthday ? 7300 : 1, "day")
                .format() || moment().add(1, "day").format(),
        repetition: props.birthday ? "years" : "",
        color: eventFields.color || "-",
        singleComment: eventFields.singleComment || false,
        generalComment: props.generalComment,
        birthday: props.birthday,
        birthdayAvatar: eventFields.birthdayAvatar || "",
        allDayEvent: eventFields.allDayEvent || props.birthday,
        singleCommentDate:
          eventFields.singleCommentDate instanceof moment
            ? eventFields.singleCommentDate.format()
            : eventFields.singleCommentDate || moment().format(),
        team:
          eventFields.team && eventFields.team[0]
            ? eventFields.team.map((row) => row.toString())
            : [],
      };
    } else {
      return {
        title: eventFields.title,
        description: eventFields.description,
        comment: eventFields.comment,
        date:
          eventFields.date instanceof moment
            ? eventFields.date.format("YYYY-MM-DD")
            : eventFields.date || moment().format("YYYY-MM-DD"),
        start:
          eventFields.startTime instanceof moment
            ? eventFields.startTime.format()
            : eventFields.start || moment().format(),
        end:
          eventFields.endTime instanceof moment
            ? eventFields.endTime.format()
            : eventFields.end || moment().format(),
        startTime:
          eventFields.startTime instanceof moment
            ? eventFields.startTime.format("HH:mm")
            : eventFields.startTime
            ? eventFields.startTime.length > 5
              ? moment(eventFields.startTime, moment.ISO_8601).format("HH:mm")
              : eventFields.startTime
            : moment().add(props.defaultClassLength, "minutes").format("HH:mm"),
        endTime:
          eventFields.endTime instanceof moment
            ? eventFields.endTime.format("HH:mm")
            : eventFields.endTime
            ? eventFields.endTime.length > 5
              ? moment(eventFields.endTime, moment.ISO_8601).format("HH:mm")
              : eventFields.endTime
            : moment().add(props.defaultClassLength, "minutes").format("HH:mm"),
        icon: props.birthday
          ? "https://firebasestorage.googleapis.com/v0/b/sederyommui.appspot.com/o/iconsCohen%2F1051)%D7%99%D7%95%D7%9D%20%D7%94%D7%95%D7%9C%D7%93%D7%AA.png?alt=media&token=7b8492c3-c7c9-4f53-b754-2af739e384a6"
          : eventFields.icon,
        startRecur:
          eventFields.startRecur instanceof moment
            ? eventFields.startRecur.format()
            : eventFields.startRecur || moment().format(),
        endRecur:
          eventFields.endRecur instanceof moment
            ? eventFields.endRecur.format()
            : eventFields.endRecur ||
              moment(props.defaultEndOfYear, "DD/MM/YYYY").format(),
        repetition: props.birthday ? "years" : eventFields.repetition,
        daysOfWeek:
          eventFields.repetition === "days"
            ? [0, 1, 2, 3, 4, 5, 6]
            : [
                eventFields.startRecur instanceof moment
                  ? eventFields.startRecur.day()
                  : eventFields.startRecur
                  ? moment(eventFields.startRecur, moment.ISO_8601).day()
                  : moment().day(),
              ],
        color: eventFields.color || "-",
        singleComment: eventFields.singleComment || false,
        generalComment: props.generalComment,
        birthday: props.birthday,
        birthdayAvatar: eventFields.birthdayAvatar || "",
        allDayEvent: eventFields.allDayEvent || props.birthday,
        singleCommentDate:
          eventFields.singleCommentDate instanceof moment
            ? eventFields.singleCommentDate.format()
            : eventFields.singleCommentDate || moment().format(),
        team:
          eventFields.team && eventFields.team[0]
            ? eventFields.team.map((row) => row.toString())
            : [],
      };
    }
  };

  const handleSave = () => {
    setLoading(true);
    const collection = firestore.collection("events");
    const formatted = formatForm();
    if (props.event) {
      collection
        .doc(props.event.id)
        .update(formatted)
        .then(() => {
          initializeForm();
          props.onClose();
        })
        .catch((error) => {
          console.error(error, formatForm());
        });
    } else {
      collection
        .add(formatted)
        .then((docRef) => {
          collection.doc(docRef.id).update({
            time: new Date(),
            id: docRef.id,
            centerId: centerId,
            classId: classId,
            createdBy: auth.currentUser.uid,
          });

          props.onClose();
          initializeForm();
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    }
  };

  const deleteEvent = () => {
    firestore
      .collection("events")
      .doc(props.event.id)
      .delete()
      .then(() => {
        initializeForm();
        props.onClose();
      });
  };

  const handleColorChange = (_, target) => {
    setEventFields({ ...eventFields, color: target.props.value });
  };

  // Birthday only
  const removeAvatar = () => {
    setBirthdayAvatar();
    setEventFields({ ...eventFields, birthdayAvatar: "" });
  };

  const handleAvatarChange = (event) => {
    if (!event) {
      return;
    }
    const files = event.target.files;
    if (!files) {
      return;
    }
    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      "image/gif",
      "image/jpeg",
      "image/png",
      "image/webp",
      "image/svg+xml",
    ];
    if (!fileTypes.includes(avatar.type)) {
      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      return;
    }

    setBirthdayAvatar(avatar);
    setLoadingAvatar(true);
    storage
      .ref(`/avatars/${moment().unix()}/${avatar}`)
      .put(avatar)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadUrl) => {
          setEventFields({
            ...eventFields,
            birthdayAvatar: downloadUrl,
          });
          setLoadingAvatar(false);
        });
      });
  };

  return (
    <React.Fragment>
      <IconSelectDialog
        open={iconSelectDialogOpen}
        onClose={(response) => {
          setIconsSelectDialogOpen(false);
          if (response) {
            setEventFields({ ...eventFields, icon: response });
          }
        }}
      />
      <Dialog open={props.open} onBackdropClick={props.onClose}>
        <DialogTitle>
          <Grid container>
            <Grid item xs={2}>
              <Tooltip title="ביטול">
                <IconButton
                  className={classes.closeButton}
                  onClick={props.onClose}
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <HelpDialog
                style={{ maxWidth: 20 }}
                videoUrl={"https://www.youtube.com/embed/7E00-WzfNvo"}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {props.birthday || (props.event && props.event.birthday)
                  ? "יום הולדת"
                  : props.generalComment ||
                    (props.event && props.event.generalComment)
                  ? "חשוב לזכור"
                  : "הוספת ארוע"}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div className={classes.loadingDiv}>
              <CircularProgress />
            </div>
          ) : props.birthday || (props.event && props.event.birthday) ? (
            <form>
              <Grid container>
                <Grid item xs={8}>
                  <TextField
                    id="title"
                    label="כותרת"
                    required
                    style={{ minWidth: 500 }}
                    InputLabelProps={{ className: classes.inputLabel }}
                    value={eventFields.title}
                    className={classes.titleTextField}
                    onChange={(event) =>
                      setEventFields({
                        ...eventFields,
                        title: event.target.value,
                      })
                    }
                  />

                  <Grid container>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                      locale={"he"}
                    >
                      <Grid item xs={4}>
                        <KeyboardDatePicker
                          disableToolbar
                          required
                          variant="inline"
                          format="DD/MM/YY"
                          margin="normal"
                          id="startRecur"
                          label="תאריך יום ההולדת"
                          style={{ minWidth: 500 }}
                          value={eventFields.startRecur}
                          className={classes.datePicker}
                          onChange={(date) =>
                            setEventFields({
                              ...eventFields,
                              startRecur: date,
                            })
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Box textAlign="center" className={classes.imageBox} mb={3}>
                    {!birthdayAvatar && !eventFields.birthdayAvatar && (
                      <>
                        <input
                          id="avatar-input"
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={handleAvatarChange}
                        />

                        <label htmlFor="avatar-input">
                          <Button
                            color="primary"
                            component="span"
                            variant="outlined"
                          >
                            תמונת החניך
                          </Button>
                        </label>
                      </>
                    )}

                    <Box mb={1.5}>
                      {birthdayAvatar && eventFields.birthdayAvatar && (
                        <Badge
                          classes={{ badge: classes.badge }}
                          badgeContent={
                            <Tooltip title="הסרה">
                              <Fab
                                classes={{ sizeSmall: classes.small }}
                                color="secondary"
                                size="small"
                                onClick={removeAvatar}
                              >
                                <Close fontSize="small" />
                              </Fab>
                            </Tooltip>
                          }
                        >
                          <Avatar
                            className={classes.birthdayAvatar}
                            alt="Avatar"
                            src={eventFields.birthdayAvatar}
                          />
                          {loadingAvatar && (
                            <Badge
                              classes={{ badge: classes.loadingBadge }}
                              badgeContent={
                                <Fade
                                  style={{ transitionDelay: "1s" }}
                                  in={loadingAvatar}
                                  unmountOnExit
                                >
                                  <CircularProgress
                                    size={120}
                                    thickness={1.8}
                                  />
                                </Fade>
                              }
                            ></Badge>
                          )}{" "}
                        </Badge>
                      )}

                      {!eventFields.birthdayAvatar && (
                        <>
                          {eventFields.birthdayAvatar && (
                            <Badge
                              classes={{ badge: classes.badge }}
                              badgeContent={
                                <Tooltip title="Remove">
                                  <Fab
                                    classes={{ sizeSmall: classes.small }}
                                    color="secondary"
                                    size="small"
                                    onClick={removeAvatar}
                                  >
                                    <Close fontSize="small" />
                                  </Fab>
                                </Tooltip>
                              }
                            >
                              {!loadingAvatar && (
                                <Badge
                                  classes={{ badge: classes.loadingBadge }}
                                  badgeContent={
                                    <CircularProgress
                                      size={120}
                                      thickness={1.8}
                                    />
                                  }
                                >
                                  <Avatar
                                    className={classes.birthdayAvatar}
                                    alt="Avatar"
                                    src={eventFields.birthdayAvatar}
                                  />
                                </Badge>
                              )}

                              {!loadingAvatar && (
                                <Avatar
                                  className={classes.birthdayAvatar}
                                  alt="Avatar"
                                  src={eventFields.birthdayAvatar}
                                />
                              )}
                            </Badge>
                          )}

                          {!eventFields.birthdayAvatar && (
                            <>
                              {loadingAvatar && (
                                <Badge
                                  classes={{ badge: classes.loadingBadge }}
                                  badgeContent={
                                    <Fade
                                      style={{ transitionDelay: "1s" }}
                                      in={loadingAvatar}
                                      unmountOnExit
                                    >
                                      <CircularProgress
                                        size={120}
                                        thickness={1.8}
                                      />
                                    </Fade>
                                  }
                                >
                                  <Avatar
                                    className={classes.birthdayAvatar}
                                    alt="Avatar"
                                  >
                                    <Person className={classes.personIcon} />
                                  </Avatar>
                                </Badge>
                              )}

                              {!loadingAvatar && (
                                <Avatar
                                  className={classes.birthdayAvatar}
                                  alt="Avatar"
                                  src={
                                    "https://firebasestorage.googleapis.com/v0/b/sederyommui.appspot.com/o/iconsCohen%2F1051)%D7%99%D7%95%D7%9D%20%D7%94%D7%95%D7%9C%D7%93%D7%AA.png?alt=media&token=7b8492c3-c7c9-4f53-b754-2af739e384a6"
                                  }
                                >
                                  {/* <Person className={classes.personIcon} /> */}
                                </Avatar>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          ) : (
            <form>
              <Grid container>
                <Grid item xs={8}>
                  <TextField
                    id="title"
                    label="כותרת"
                    required
                    InputLabelProps={{ className: classes.inputLabel }}
                    value={eventFields.title}
                    className={classes.titleTextField}
                    onChange={(event) =>
                      setEventFields({
                        ...eventFields,
                        title: event.target.value,
                      })
                    }
                  />

                  {!props.generalComment && (
                    <TextField
                      id="description"
                      label="תאור"
                      InputLabelProps={{ className: classes.inputLabel }}
                      value={eventFields.description}
                      multiline={true}
                      rows={2}
                      className={classes.descriptionTextField}
                      onChange={(event) =>
                        setEventFields({
                          ...eventFields,
                          description: event.target.value,
                        })
                      }
                    />
                  )}

                  <Autocomplete
                    id="iconSelect"
                    className={classes.titleTextField}
                    options={icons}
                    loading={loadingIcons}
                    freeSolo
                    getOptionLabel={(option) => option.name || ""}
                    onChange={(_event, value) => {
                      if (value) {
                        setEventFields({ ...eventFields, icon: value.image });
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        return (
                          <Chip
                            avatar={
                              <Avatar alt={option.name} src={option.image} />
                            }
                            variant="outlined"
                            label={option.name}
                            {...getTagProps({ index })}
                          />
                        );
                      })
                    }
                    renderOption={(option, state) => {
                      return (
                        <ListItem selected={state.selected} component={"span"}>
                          <Avatar
                            className={classes.listAvatar}
                            alt={option.name}
                            src={option.image}
                          />
                          {option.name.split(")")[1]}
                        </ListItem>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="סמל" placeholder="" />
                    )}
                  />
                </Grid>

                <Grid item xs={4} className={classes.avatarContainer}>
                  <Avatar className={classes.avatar}>
                    <img
                      alt={`${eventFields.icon}`}
                      className={classes.avatarImage}
                      src={eventFields.icon}
                    />
                  </Avatar>
                </Grid>
              </Grid>
              <FormControl className={classes.formControl}>
                <InputLabel
                  id="repetition-label"
                  className={classes.inputLabel}
                >
                  אירוע חוזר
                </InputLabel>
                <Select
                  labelId="repetition-label"
                  id="repetition"
                  value={eventFields.repetition}
                  onChange={(event) =>
                    setEventFields({
                      ...eventFields,
                      repetition: event.target.value,
                    })
                  }
                >
                  <MenuItem value={""}>ללא חזרה</MenuItem>
                  <MenuItem value={"days"}>כל יום</MenuItem>
                  <MenuItem value={"weeks"} default>
                    {`כל שבוע ב${
                      eventFields.startRecur instanceof moment
                        ? eventFields.startRecur.format("dddd")
                        : moment(
                            eventFields.startRecur,
                            moment.ISO_8601
                          ).format("dddd")
                    }`}
                  </MenuItem>
                  <MenuItem value={"years"}>
                    {`כל שנה ב${
                      eventFields.startRecur instanceof moment
                        ? eventFields.startRecur.format("D MMMM")
                        : moment(
                            eventFields.startRecur,
                            moment.ISO_8601
                          ).format("D MMMM")
                    }`}
                  </MenuItem>
                </Select>
              </FormControl>
              {eventFields.repetition === "" && (
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale={"he"}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    required
                    disabled={eventFields.repetition !== ""}
                    variant="inline"
                    format="DD/MM/YY"
                    margin="normal"
                    id="date"
                    label="תאריך"
                    value={eventFields.date}
                    className={classes.datePicker}
                    onChange={(date) =>
                      setEventFields({
                        ...eventFields,
                        date: date,
                      })
                    }
                  />
                </MuiPickersUtilsProvider>
              )}
              {eventFields.repetition && (
                <Grid container>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={"he"}
                  >
                    <Grid item xs={4}>
                      <KeyboardDatePicker
                        disableToolbar
                        required
                        variant="inline"
                        format="DD/MM/YY"
                        margin="normal"
                        id="startRecur"
                        label="מתאריך"
                        value={eventFields.startRecur}
                        className={classes.datePicker}
                        onChange={(date) =>
                          setEventFields({
                            ...eventFields,
                            startRecur: date,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <KeyboardDatePicker
                        disableToolbar
                        required
                        variant="inline"
                        format="DD/MM/YY"
                        margin="normal"
                        id="endRecur"
                        label="עד תאריך"
                        value={eventFields.endRecur}
                        className={classes.datePicker}
                        onChange={(date) =>
                          setEventFields({
                            ...eventFields,
                            endRecur: date,
                          })
                        }
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              )}
              <>
                {eventFields.allDayEvent !== true && (
                  <Grid container>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                      locale={"he"}
                    >
                      <Grid item xs={4}>
                        <KeyboardTimePicker
                          label="התחלה"
                          clearable
                          required
                          placeholder="08:00"
                          ampm={false}
                          value={eventFields.startTime}
                          className={classes.datePicker}
                          onChange={(date) =>
                            setEventFields({
                              ...eventFields,
                              startTime: date,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyboardTimePicker
                          label="סיום"
                          clearable
                          required
                          locale={"he-IL"}
                          ampm={false}
                          placeholder="08:00"
                          value={eventFields.endTime}
                          className={classes.datePicker}
                          onChange={(date) =>
                            setEventFields({
                              ...eventFields,
                              endTime: date,
                            })
                          }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eventFields.allDayEvent || false}
                        onChange={(event) =>
                          setEventFields({
                            ...eventFields,
                            allDayEvent: event.target.checked,
                          })
                        }
                        name="checkedA"
                      />
                    }
                    label="כל היום"
                  />
                </Grid>
                {!props.generalComment && (
                  <TeamSelect
                    options={teamNames}
                    value={eventFields.team}
                    onChange={(_event, value) =>
                      setEventFields({
                        ...eventFields,
                        team: value,
                      })
                    }
                  />
                )}
              </>
              {!props.generalComment && (
                <>
                  <FormControl required className={classes.formControl}>
                    <InputLabel
                      className={classes.inputLabel}
                      id="color-select-label"
                    >
                      צבע
                    </InputLabel>
                    <Select
                      labelId="color-select-label"
                      id="color-select"
                      value={eventFields.color || "-"}
                      onChange={handleColorChange}
                      label="color"
                    >
                      <MenuItem value="-">
                        <em>-</em>
                      </MenuItem>
                      {Object.values(appearance.colors).map((color) => {
                        return (
                          <MenuItem value={color.id} key={color.id}>
                            <Hidden xsDown>
                              <ListItemIcon>
                                <Check
                                  style={{ background: `${color.import[500]}` }}
                                />
                              </ListItemIcon>
                            </Hidden>
                            {color.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    id="comment"
                    label="הערות"
                    InputLabelProps={{ className: classes.inputLabel }}
                    value={eventFields.comment}
                    multiline={true}
                    rowsMax={3}
                    rows={2}
                    className={classes.descriptionTextField}
                    onChange={(event) =>
                      setEventFields({
                        ...eventFields,
                        comment: `${event.target.value}`,
                      })
                    }
                  />
                  {(eventFields.repetition === "weeks" ||
                    eventFields.repetition === "days") && (
                    <Grid container>
                      <Grid item xs={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={eventFields.singleComment || false}
                              onChange={(event) =>
                                setEventFields({
                                  ...eventFields,
                                  singleComment: event.target.checked,
                                })
                              }
                              name="checkedA"
                            />
                          }
                          label="הערה חד פעמית"
                        />
                      </Grid>
                      {eventFields.singleComment && (
                        <Grid item xs={4}>
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={MomentUtils}
                            locale={"he"}
                          >
                            <KeyboardDatePicker
                              disableToolbar
                              required
                              variant="inline"
                              format="DD/MM/YY"
                              margin="normal"
                              id="singleCommentDate"
                              label="לתאריך"
                              helperText={"ההערה תופיע רק בתאריך זה"}
                              value={eventFields.singleCommentDate}
                              className={classes.datePicker}
                              onChange={(date) =>
                                setEventFields({
                                  ...eventFields,
                                  singleCommentDate: date,
                                })
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </>
              )}
            </form>
          )}
        </DialogContent>
        <DialogActions>
          {props.event && (
            <Button
              variant="contained"
              className={classes.deleteButton}
              onClick={deleteEvent}
            >
              הסרה
            </Button>
          )}
          {(!props.event || (props.event && !props.event.birthday)) && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
              disabled={!eventFields.title && !eventFields.icon}
            >
              {props.event ? "עדכון ארוע" : "יצירת ארוע"}
            </Button>
          )}
          <Button color="primary" onClick={props.onClose}>
            חזרה
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

EventDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  defaultEndOfYear: PropTypes.string.isRequired,
  defaultClassLength: PropTypes.string.isRequired,
  event: PropTypes.object,
  generalComment: PropTypes.bool,
};
