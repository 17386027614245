import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Avatar,
  Chip,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Message } from "@material-ui/icons";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  currentActivityCard: {
    margin: 10,
    borderRadius: 25,
    border: "7px solid gray",
    padding: 50,
    boxShadow: "20px 20px 20px 1px rgba(50, 50, 50, 0.5)",
    animation: " appear 500ms ease-out forwards",
    height: "calc(50vh - 48px)",
  },
  eventDuration: {
    position: "absolute",
    left: 10,
    top: 10,
  },
  iconDiv: {
    position: "relative",
    width: "fit-content",
    marginLeft: "auto",
  },
  icon: {
    marginLeft: 10,
    marginRight: 10,
  },
  circle: {
    strokeLinecap: "round",
  },
  progress: {
    position: "absolute",
    top: -theme.spacing(2.2),
    left: -theme.spacing(2.2),
    [theme.breakpoints.down("xs")]: {
      top: -theme.spacing(1.75),
      left: -theme.spacing(1.75),
    },
  },
  avatarLarge: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  },
  messageDiv: {
    position: "absolute",
    display: "flex",
    bottom: 30,
    padding: 5,
  },
  imgLarge: {
    width: theme.spacing(22),
    height: theme.spacing(22),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  img: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  chip: {
    margin: 5,
    padding: 5,
    marginTop: 15,
    [theme.breakpoints.up("sm")]: {
      height: 50,
      fontSize: 20,
      borderRadius: 30,
    },
    root: {
      avatar: {
        height: 50,
        width: 50,
      },
    },
  },
}));

export default function CenterCard(props) {
  const classes = useStyles();
  const theme = useTheme();

  const downSM = useMediaQuery(theme.breakpoints.down("sm"));
  const downXS = useMediaQuery(theme.breakpoints.down("xs"));

  const currentEvent = props.currentEvent;
  return (
    <Card
      className={classes.currentActivityCard}
      style={{
        border:
          currentEvent && currentEvent.color
            ? `10px solid ${currentEvent.color}`
            : "10px solid gray",
      }}
    >
      <CardContent>
        <Grid container alignItems="stretch">
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant={downSM ? "h4" : "h3"} fontSize={2}>
              {currentEvent ? currentEvent.title : <Skeleton />}
            </Typography>
            <Typography variant={downSM ? "h6" : "h4"} style={{ padding: 5 }}>
              {currentEvent ? currentEvent.description : <Skeleton />}
            </Typography>
            <Typography
              variant={downSM ? "caption" : "h4"}
              className={classes.eventDuration}
            >
              {currentEvent ? (
                `${moment(currentEvent.start, moment.ISO_8601).format(
                  "HH:mm"
                )} - ${moment(currentEvent.end, moment.ISO_8601).format(
                  "HH:mm"
                )}`
              ) : (
                <Skeleton />
              )}
            </Typography>
            {currentEvent &&
              currentEvent.team &&
              currentEvent.team.map((member) => (
                <Chip
                  key={member}
                  className={classes.chip}
                  avatar={
                    <Avatar
                      style={{
                        width: downXS ? 24 : 65,
                        height: downXS ? 24 : 65,
                        marginRight: 0,
                      }}
                      alt={member.split(",")[0]}
                      src={member.split(",")[1]}
                    />
                  }
                  label={member.split(",")[0]}
                />
              ))}
            {currentEvent &&
              currentEvent.comment &&
              (!currentEvent.singleComment ||
                (currentEvent.singleComment &&
                  currentEvent.singleCommentDate.includes(
                    moment().format("YYYY-MM-DD")
                  ))) && (
                <div className={classes.messageDiv}>
                  <Message color={"primary"} className={classes.icon} />
                  <Typography variant="h5" style={{ whiteSpace: "pre" }}>
                    {currentEvent.comment}
                  </Typography>
                </div>
              )}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <div className={classes.iconDiv}>
              {currentEvent ? (
                <>
                  <Avatar className={classes.avatarLarge}>
                    <img
                      alt={`current event ${currentEvent.icon}`}
                      src={currentEvent.icon}
                      className={classes.imgLarge}
                    />
                  </Avatar>
                  <CircularProgress
                    variant="static"
                    value={props.classProgress}
                    className={classes.progress}
                    classes={{
                      circle: classes.circle,
                    }}
                    style={{ color: currentEvent.color || "" }}
                    size={downXS ? 150 : 280}
                    thickness={downXS ? 5 : 4}
                  />
                </>
              ) : (
                <Skeleton variant={"circle"} width={220} height={220} />
              )}
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CenterCard.propTypes = {
  currentEvent: PropTypes.object,
  classProgress: PropTypes.number.isRequired,
};
